import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {StandartButton, IconButton, OutlinedButton, TextButton} from "./StandartButton";
import styled, {keyframes} from "styled-components";
import SpinnerIcon from "GUI_MAIN/icons/SpinnerIcon"

export const CommonButtonWrapper = styled.div`
`;

const setIconMargin = (iconPlacement) => {
    if(iconPlacement === 'right') return '0 0 0 8px'
    if(iconPlacement === 'center') return '0'

    return '0 8px 0 0'
}

const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  margin: ${props => setIconMargin(props.iconPlacement)};
  width: 20px;
  height: 20px;

  svg {
      width: 100%;
      height: 100%;
  }
`;

const SpinnerRotate = keyframes`
    0% {
      transform: rotate(0deg);
    }

    90% {
      transform: rotate(340deg);
    }

  95% {
    transform: rotate(350deg);
  }

    100%{
      transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  animation: ${SpinnerRotate} 1s ease-in infinite;
`;

class Button extends PureComponent {
    state = {
        buttonWidth: '',
        activeButton: false,
    };

    static propTypes = {
        onClick: PropTypes.func.isRequired,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.element,
        ]).isRequired,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        margin: PropTypes.string,
        justifyContent: PropTypes.string,
        minWidthAuto: PropTypes.bool,
        width: PropTypes.string,
        dataTip: PropTypes.string,
        padding: PropTypes.string,
        BGcolor: PropTypes.string,
        round: PropTypes.bool,
        addIcon: PropTypes.element,
        needShadow: PropTypes.bool,
        needBorder: PropTypes.bool,
        fontSize: PropTypes.string,
        borderRadius: PropTypes.string,
        letterSpacing: PropTypes.string,
        iconBorder: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        type: 'standart',
        margin: '0',
        needShadow: true,
        needBorder: true,
        dropShadow: false,
        iconBorder: false,
        iconPlacement: 'right',
        mustBeActive: false,
        isLoading: false
    };


    onClick = (e, event) => {
        if (!this.props.disabled) {
            if (this.props.onClick)
                this.props.onClick(e, event);
        }
    };

    renderButton = () => {

        const {activeButton} = this.state;

        const {
            disabled,
            label,
            type,
            margin,
            minWidthAuto,
            width,
            size,
            dataTip,
            padding,
            BGcolor,
            round,
            addIcon,
            fontSize,
            fontWeight,
            borderRadius,
            letterSpacing,
            iconBorder,
            iconPlacement,
            mustBeActive,
            isLoading,
            textColor,
            BGcolorHover,
            dataTestControl,
        } = this.props;

        switch(type) {
            case 'standart':
            case 'default':
                return (
                    <StandartButton
                        onClick={this.onClick}
                        disabled={disabled}
                        margin={margin}
                        active={activeButton}
                        width={width}
                        minWidthAuto={minWidthAuto}
                        padding={padding}
                        BGcolor={BGcolor}
                        textColor={textColor}
                        BGcolorHover={BGcolorHover}
                        round={round}
                        addIcon={addIcon}
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        letterSpacing={letterSpacing}
                        data-test-control={dataTestControl}
                    >
                        <span>
                            {label}
                        </span>
                        {
                            addIcon && <IconWrapper iconPlacement={iconPlacement}>{addIcon}</IconWrapper>
                        }
                        {
                            isLoading &&
                            <Spinner>
                                <SpinnerIcon/>
                            </Spinner>
                        }
                    </StandartButton>
                );
            case 'outlined':
                return (
                    <OutlinedButton
                        onClick={this.onClick}
                        disabled={disabled}
                        margin={margin}
                        active={activeButton}
                        width={width}
                        minWidthAuto={minWidthAuto}
                        padding={padding}
                        round={round}
                        BGcolor={BGcolor}
                        fontSize={fontSize}
                        mustBeActive={mustBeActive}
                        addIcon={addIcon}
                        data-test-control={dataTestControl}
                    >
                        {
                            addIcon && <IconWrapper iconPlacement={iconPlacement}>{addIcon}</IconWrapper>
                        }
                        <span>
                            {label}
                        </span>
                        {
                            isLoading &&
                            <Spinner>
                                <SpinnerIcon/>
                            </Spinner>
                        }
                    </OutlinedButton>
                );
            case 'icon':
                return(
                    <IconButton
                        onClick={this.onClick}
                        disabled={disabled}
                        margin={margin}
                        active={activeButton}
                        size={size}
                        padding={padding}
                        BGcolor={BGcolor}
                        data-tip={dataTip}
                        borderRadius={borderRadius}
                        iconBorder={iconBorder}
                        data-test-control={dataTestControl}
                    >
                        {label}
                    </IconButton>

                );
            case 'text':
                return(
                  <TextButton
                    onClick={this.onClick}
                    disabled={disabled}
                    margin={margin}
                    data-test-control={dataTestControl}
                  >
                      {
                          addIcon && addIcon
                      }
                      {label}
                  </TextButton>
                )

            default:
                return null
        }
    };

    render() {
        return (
            <CommonButtonWrapper>
                {this.renderButton()}
            </CommonButtonWrapper>
        );
    }
}

export default Button;
