import React, {PureComponent} from 'react';
import styled, {withTheme} from 'styled-components';
import {Link, withRouter} from "react-router-dom"
import { inject } from 'mobx-react';
import ReactTooltip from "react-tooltip";
import {EOLocale, Translator} from "eo-locale";
import {
    ACCESS_RIGHT_ACTION, ACCESS_RIGHT_INCLUDES,
    ACCESS_RIGHT_OBJECT
} from "MODEL_STORE/DataSource/Constants/user_access_rights";
import {locales} from "../../../../Locale";
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import { MenuItemAppointment, MenuItemPatientList, MenuItemCareTeams, MenuItemDashboard, MenuItemProtocols, MenuItemServiceRequest } from '../../../../GUI_TB/Menu/MenuItem';

const MenuWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  .__react_component_tooltip {
    z-index: 99999;
  }
`;

const ListItems = styled.ul`
  width: 100%;
  padding: 0;
  list-style-type: none`;

const Item = styled.li`
  a{
     color: ${props => props.theme.darkMainThemeColor};
     font-size: ${props => props.theme.mediumFontSize};
    &:hover{
     color: ${props => props.theme.darkMainThemeColor};
     text-decoration: none;
    }
  }

`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a{
    display: flex;
    flex: 1 1;

    &:hover {
      &>div {
        color: ${props => props.theme.mainBlueColor};
      }
      svg path{
        fill: ${props => props.theme.mainBlueColor};
      }
    }
  }
`;

const Content = styled.div`
  font-family: ${props => props.sublink ? props.theme.OpenSansRegular : props.theme.OpenSansSemiBold};
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${props => props.padding || '12px 16px'};
  padding-left: ${props => props.padding ? '50px' : props.isMenuShow ? props.isActive ? '13px' : '13px' : '13px'};
  background-color: ${props => props.theme.whiteColor};
  color: ${props => props.isActive ? props.theme.mainBlueColor : props.theme.darkMainTextColor};
  border-right: ${props => props.isActive && props.isMenuShow && '5px solid transparent'};

  &:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.mainDarkBlueColor};
    display: ${props => props.isActive ? 'block' : 'none'};
  }
`;

const IconWrapper = styled.div`
  width: ${props => props.width ? props.width : '24px'};
  height: 24px;
  min-width: ${props => props.width ? props.width : '24px'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg{
    width: 100%;
    height: auto;
  }`;

const LinkText = styled.div`
  flex-grow: 1;
  opacity: ${props => props.isMenuShow ? "0" : "1"};
  transition: opacity .3s ease;
`;

const Amount = styled.div`
  margin: 0 10px 0 5px;
  padding: 5px;
  font-family: ${props => props.theme.OpenSansSemiBold};
  font-size: ${props=> props.theme.minFontSize};
  line-height: 12px;
  background-color: ${props => props.theme.amountBgColor};
  color: ${props => props.theme.darkMainThemeColor};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center`;

@withRouter
@withTheme
@inject('ContextualMenuStore')
@inject('AuthStore')
export default class Menu extends PureComponent{
    data=[
        MenuItemDashboard,
        MenuItemPatientList,
        MenuItemAppointment,
        MenuItemCareTeams,
        MenuItemServiceRequest,
        MenuItemProtocols
    ];

    constructor(props) {
        super(props);
        const pathname = window.location.pathname.split('/');
        let firstPrefix = "";
        let selectDataItem = this.data.filter(i => i)[0];
        const findMenuItem = this.data.filter(e => e).find(i => {
            const pathParsed = i.linkTo.split('/');
            if (pathname.find(j => j === pathParsed[1]))
                return i;
        });
        if (findMenuItem)
            props.setActiveMenuItem(findMenuItem.linkText);
        else {
            props.setActiveMenuItem(selectDataItem.linkText);
        }

        this.state = {
            subMenu: {}
        };

        this.unlisten = this.props.history.listen((location, action) => {
            this.onLocationChange(this)
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    onLocationChange = (_this) => {
        const pathname = window.location.pathname.split('/');
        const findMenuItem = _this.data.filter(e => e).find(i => {
            const pathParsed = i.linkTo.split('/');
            if (pathname.find(j => j === pathParsed[1]))
                return i;
        });
        if (findMenuItem) {
            if (_this.props.activeMenuItem !== findMenuItem.linkText) {
                _this.props.setActiveMenuItem(findMenuItem.linkText);
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild();
    }

    /**
     *
     */

    render(){
        const {isMenuShow, AuthStore, theme, setActiveMenuItem, activeMenuItem} = this.props;

        // console.log('AuthStore', AuthStore.currentUserData);
        const translator = new Translator(HeaderStore.locale, locales);
        return(
            <MenuWrapper>
                <ListItems>
                    {
                        this.data.filter(i=>i).map((elem, item)=>{
                            if (!AuthStore.currentUserData.isRoot()) {
                                if (elem.id === 1) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_DESKTOP, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 2 ) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_PATIENT, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL) )
                                        return null;
                                }
                                if (elem.id === 3) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_APPOINTMENT, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 4) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_SERVICEREQUEST, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 5) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_PATIENT, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                /*if (elem.id === 5) {
                                    if (!AuthStore.currentUserData.permission.find(perm => perm === "TEMP_medications_list"))
                                            return null
                                }*/
                                if (elem.id === 6) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_PRACTITIONER, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 7) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_ORGANIZATION, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 8) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_PROTOCOL, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 9) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_PLAN, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 10) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_TEMPLATE, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 11) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_SECURITY_ROLES, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 12) {
                                    if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.MENU_ALLDOCUMENTS, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                        return null
                                }
                                if (elem.id === 20) { // участники консилиума
                                  if (!AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.CARETEAM_MENU, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL))
                                      return null
                                }
                            }
                            return (
                                <Item key={item}>
                                    <ItemHeader>
                                        <Link to={elem.linkTo}
                                            onClick={() => setActiveMenuItem(elem.linkText)}
                                            data-test-control={`main-menu-item-${elem.id}`}
                                        >
                                            <Content display={'flex'} isActive={activeMenuItem === elem.linkText} isMenuShow={isMenuShow}>
                                                {isMenuShow ?
                                                    elem.linkImg && elem.subMenu ?
                                                        <IconWrapper data-tip={translator.getMessageById(elem.linkText)} isMenuShow={isMenuShow} data-place={'right'}>
                                                            <div style={{position: 'absolute', left:'-15px', top:'0', width: '50px', height: '100%'}}/>
                                                            {elem.linkImg(activeMenuItem === elem.linkText ? theme.mainBlueColor : theme.blackColor)}
                                                        </IconWrapper>
                                                        :
                                                        <IconWrapper data-tip={translator.getMessageById(elem.linkText)}
                                                                     isMenuShow={isMenuShow} data-place={'right'}>
                                                            {elem.linkImg(activeMenuItem === elem.linkText ? theme.mainBlueColor : theme.blackColor)}
                                                        </IconWrapper>
                                                    :
                                                    <IconWrapper>{elem.linkImg(activeMenuItem === elem.linkText ? theme.mainBlueColor : theme.blackColor)}</IconWrapper>
                                                }
                                                <LinkText isMenuShow={isMenuShow}>
                                                    <EOLocale.Text id={elem.linkText}/>
                                                </LinkText>
                                                {
                                                    elem.amount && <Amount>{elem.amount}</Amount>
                                                }
                                            </Content>
                                        </Link>
                                    </ItemHeader>
                                </Item>
                            )
                        })
                    }
                </ListItems>
            </MenuWrapper>
        )
    }
}
