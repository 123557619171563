import React from "react";
import { observable } from "mobx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ContextData } from "./ContextData";
import { ServiceRequestForm } from "./ServiceRequestForm";
import { PatientInformation } from "./PatientInformation";
import { PopupBasic } from "GUI_MAIN/Elements/Popups/PopupBasic";
import { PatientServiceRequests } from "./PatientServiceRequests";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import Logger from "../../../../logger";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";

/**
 *
 */
const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

/**
 *
 */
const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
`;

/**
 *
 */
const FooterContentWrapper = styled.div`
    font-family: ${props => props.theme.OpenSansRegular};
    font-size: ${props => props.theme.mediumFontSize};
    text-decoration: underline;

    a{
        color: ${props => props.theme.mainDarkBlueColor};
    }
`;

/**
 * https://jira.mmdx.ru/browse/FCSPOK-321
 * 1. Кнопка нажата без открытой карты пациента
 *  - Выполняется переход на главную страницу СОК.
 * 2. Кнопка нажата при открытой карте пациента без онкологической специфики:
 *  - Выполняется переход на главную страницу СОК с модальным окном, в котором пользователь может перейти к ранее созданной заявке или на главную страницу.
 * 3. Кнопка нажата при открытой карте пациента с онкологической спецификой, в которой указан онкологический диагноз
 *  - Выполняется переход на главную страницу СОК с модальным окном, в котором пользователь может создать новую заявку на ОК, либо перейти к ранее созданной заявке или на главную страницу
 */
@observer
export class DialogPopup extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        onClose: PropTypes.func.isRequired
    }

    /**
     * данные контекста
     */
    @observable contextData = null;

    /**
     * идентификатор контекста
     */
    get contextId() {
        return localStorage.getItem("INTEGRATION_CONTEXT_ID");
    }

    /**
     * при монтировании компонента
     */
    componentDidMount() {
        if ( ! this.contextId ) {
            return this.props.onClose();
        }
        basicRequestGet(`/api/integrations/v2/context/${this.contextId}`).then(response => {
            const contextData = new ContextData(response.data);
            if ( ! contextData.patient ) {
                localStorage.removeItem("INTEGRATION_CONTEXT_ID");
                return this.props.onClose();
            }
            this.contextData = contextData;
            Logger.showIntegrationMIS({contextId: this.contextId, event_result: "Успешно"});
        }, error => {
            Logger.showIntegrationMIS({contextId: this.contextId, event_result: "Неуспешно"});
            if (error.response.status == 403) {
                console.log("удаляем сессию авторизованного пользователя");
                AuthStore.logout({
                    afterUrl: window.location.pathname + window.location.search
                });
            }
            console.error(error);
            return this.props.onClose();
        });
    }

    /**
     * свойства попапа
     */
    get popupProps() {
        return {
            title: "",
            size: "emiasDialog",
            onClose: this.props.onClose,
            bottomActions: this.popupButtons,
            bottomActionsPosition: "flex-start",
        }
    }

    /**
     * кнопки попапа
     */
    get popupButtons() {
        return (
            <FooterWrapper>
                <FooterContentWrapper>
                    <Link to={"/"}>
                        Перейти на главную страницу
                    </Link>
                </FooterContentWrapper>
            </FooterWrapper>
        )
    }

    /**
     * можно ли создать новую заявку
     */
    get canCreateServiceRequest() {
        return !! this.contextData.conditions.length;
    }

    /**
     * отрисовка компонента
     */
    render() {
        if ( ! this.contextData ) {
            return null; // нет данных
        }
        return (
            <PopupBasic {...this.popupProps}>
                <PopupContent>
                    <PatientInformation
                        patient={this.contextData.patient}
                    />
                    <PatientServiceRequests
                        contextId={this.contextId}
                    />
                    { this.canCreateServiceRequest && (
                        <ServiceRequestForm
                            contextId={this.contextId}
                            contextData={this.contextData}
                        />
                    ) }
                </PopupContent>
            </PopupBasic>
        );
    }
}
