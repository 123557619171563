import React from "react";
import _get from "lodash/get";
import { observable } from "mobx";
import PropTypes from "prop-types";
import styledMap from "styled-map";
import { observer } from "mobx-react";
import _orderBy from "lodash/orderBy";
import styled from "styled-components";
import { Paths } from "GUI_TB/Routs/Paths";
import { translate } from "Locale/translate";
import Button from "GUI_MAIN/Elements/Button";
import { withRouter } from "react-router-dom";
import RadioButton from "GUI_MAIN/Elements/RadioButton";
import StyledReactSelect from "GUI_MAIN/Elements/StyledReactSelect";
import { ToasterStore } from "MODEL_STORE/DataSource/Stores/ToasterStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";

/**
 *
 */
const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${props => props.flex || "1 1"};
    padding: ${props => props.padding || "0"};
`;

/**
 *
 */
const Row = styled.div`
    display: flex;
    margin-bottom: ${props => props.marginBottom || "5px"};
    align-items: center;
`;
/**
 *
 */
const Title = styled.div`
    font-size: ${props => props.theme.bigFontSize};
    font-family: ${props => props.theme.OpenSansSemiBold};
    color: ${props => props.theme.darkMainTextColor};
`;

/**
 *
 */
const Label = styled.div`
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${styledMap`
        semibold: ${props => props.theme.OpenSansSemiBold};
        default: ${props => props.theme.OpenSansRegular};
    `};
    color: ${props => props.theme.darkMainTextColor};
`;

/**
 * создание новой заявки
 * https://jira.mmdx.ru/browse/FCSPOK-321
 */
@withRouter
@observer
export class ServiceRequestForm extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        contextId: PropTypes.string.isRequired,
        contextData: PropTypes.object.isRequired,
    }

    /**
     * при монтировании компонента
     */
    componentDidMount() {
        basicRequestGet("/api/mm/ValueSet/fcsp-vs-nsi-1-2-643-5-1-13-13-11-1506/$expand").then(response => {
            const concepts = _get(response.data.data, ["expansion", "contains"], []);
            this.consiliumReasonConcepts = _orderBy(concepts, concept => parseInt(_get(concept, "property.sorder", 0)));
        });
    }

    /**
     * тип консилиума
     */
    @observable consiliumType = "";

    /**
     * цель консилиума
     */
    @observable consiliumReasonCode = "";

    /**
     * концепты целей консилиума из справочника
     */
    @observable consiliumReasonConcepts = [];

    /**
     * выбранная цель консилиума
     */
    get consiliumReasonValue() {
        const { consiliumReasonConcept } = this;
        return consiliumReasonConcept ? {
            "value": consiliumReasonConcept.code,
            "label": consiliumReasonConcept.display
        } : "";
    }

    /**
     * концепт цели консилиума
     */
    get consiliumReasonConcept() {
        return this.consiliumReasonConcepts.find(concept => concept.code == this.consiliumReasonCode);
    }

    /**
     * цели консилиума
     */
    get consiliumReasonOptions() {
        return this.consiliumReasonConcepts.map(concept => ({
            "value": concept.code,
            "label": concept.display
        }))
    }

    /**
     * изменение цели консилиума
     */
    onChangeConsiliumReason = (option = null) => {
        this.consiliumReasonCode = option ? option.value : "";
    }

    /**
     * поиск ресурса
     */
    findResourceByCode = (resources, resourceType, code) => {
        const element = resourceType == "Composition" ? "type": "code";
        return resources.find(resource => {
            return !! (
                resource.resourceType == resourceType
                &&
                _get(resource, [element, "coding"], []).find(coding => {
                    return _get(coding, "code") == code;
                })
            )
        });
    }

    /**
     * при клике на кнопку
     */
    onSubmit = () => {
        if ( ! this.consiliumType ) {
            return ToasterStore.warning(translate("TT011630"));
        }
        if ( ! this.consiliumReasonCode ) {
            return ToasterStore.warning(translate("TT011640"));
        }
        basicRequestPost("/api/integrations/v2/servicerequest/create", {
            "context_id": this.props.contextId
        }).then(response => {
            const resources = response.data.entry.map(entry => entry.resource);
            const patient = resources.find(resource => resource.resourceType == "Patient");
            const conditions = resources.filter(resource => resource.resourceType == "Condition");
            const conditionAnamnesis = this.findResourceByCode(resources, "Composition", "8684-9999");
            if ( conditionAnamnesis ) {
                localStorage.setItem('contextConditionAnamnesis', JSON.stringify(conditionAnamnesis));
            }
            // let text = ''
            // const patientComplaints = this.findResourceByCode(resources, "Observation", "10154-3");
            // if ( patientComplaints && patientComplaints.value) {
            //     text += patientComplaints['value']['string'];
            // }
            // const patientLocalState = this.findResourceByCode(resources, "Observation", "status-localis");
            // if ( patientLocalState && patientLocalState.value) {
            //     text += patientLocalState.value;
            // }
            const patientGeneralState = this.findResourceByCode(resources, "Observation", "10210-3");
            if ( patientGeneralState ) {
                localStorage.setItem('contextPatientGeneralStateIdentifier', patientGeneralState["id"]);
            }
            const patientBSI = this.findResourceByCode(resources, "Observation", "10");
            if ( patientBSI ) {               
                localStorage.setItem('contextpatientBSIIdentifier', patientBSI["id"]);
            }
            const ecogStatus = this.findResourceByCode(resources, "Observation", "53");
            if ( ecogStatus ) {
                localStorage.setItem('contextPatientEcogStatusIdentifier', ecogStatus["id"]);
            }
            const patientWeight = this.findResourceByCode(resources, "Observation", "50");
            if ( patientWeight ) {
                localStorage.setItem('contextPatientWeightIdentifier', patientWeight["id"]);
            }
            const patientHeight = this.findResourceByCode(resources, "Observation", "57");
            if ( patientHeight ) {
                localStorage.setItem('contextPatientHeightIdentifier', patientHeight["id"]);
            }
            const patientPPT = this.findResourceByCode(resources, "Observation", "227");
            if ( patientPPT ) {
                localStorage.setItem('contextPatientPPTIdentifier', patientPPT["id"]);
            }
            const patientCreatinine = this.findResourceByCode(resources, "Observation", "1006477");
            if ( patientCreatinine ) {
                localStorage.setItem('contextPatientCreatinineIdentifier', patientCreatinine["id"]);
            }
            const patientSerumCreatinine = this.findResourceByCode(resources, "Observation", "1016112");
            if ( patientSerumCreatinine ) {
                localStorage.setItem('contextpatientSerumCreatinineIdentifier', patientSerumCreatinine["id"]);
            }
            const karnofskyIndex = this.findResourceByCode(resources, "Observation", "54");
            if ( karnofskyIndex ) {
                localStorage.setItem('contextPatientKarnofskyIndexIdentifier', karnofskyIndex["id"]);
            }
            const patientSeverity = this.findResourceByCode(resources, "Observation", "patient-severity");
            if ( patientSeverity ) {
                localStorage.setItem('contextPatientSeverityIdentifier', patientSeverity["id"]);
            }
            localStorage.setItem('contextPatientId', patient.id);
            localStorage.setItem('contextConsilium', this.consiliumType);
            localStorage.setItem('contextConditions', conditions.map(resource => resource.id).join(","));
            localStorage.setItem('contextTargetConsilium', JSON.stringify(this.consiliumReasonConcept));
            return this.props.history.push(Paths.servicerequest.create.path());
        }).catch(error => {
            console.error(error);
            return ToasterStore.error("При создании заявки произошла ошибка");
        });
    }

    /**
     * отрисовка компонента
     */
    render() {
        return (
            <Column>
                <Row marginBottom={"16px"}>
                    <Title>
                        { translate("TT020120") }
                    </Title>
                </Row>
                <Row>
                    <Column flex={"0 0 130px"}>
                        <Label semibold>
                            { translate("TT020130") }
                        </Label>
                    </Column>
                    <Column>
                        <Label>
                            { this.props.contextData.conditionCodes }
                        </Label>
                    </Column>
                </Row>
                <Row>
                    <Column flex={"0 0 130px"}>
                        <Label semibold>
                            { translate("TT020140") }
                        </Label>
                    </Column>
                    <Column>
                        <Row>
                            <Column flex={"0 0 auto"} padding={"0 16px 0 0"}>
                                <RadioButton
                                    id={"primary-consilium"}
                                    label={translate("TT019130")}
                                    checked={this.consiliumType == "primary"}
                                    onClick={() => this.consiliumType = "primary"}
                                />
                            </Column>
                            <Column flex={"0 0 auto"}>
                                <RadioButton
                                    id={"repeated-consilium"}
                                    label={translate("TT019140")}
                                    checked={this.consiliumType == "repeated"}
                                    onClick={() => this.consiliumType = "repeated"}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <Row marginBottom={"24px"}>
                    <Column flex={"0 0 130px"}>
                        <Label semibold>
                            { translate("TT020150") }
                        </Label>
                    </Column>
                    <Column flex={"0 0 525px"}>
                        <StyledReactSelect
                            value={this.consiliumReasonValue}
                            placeholder={translate("TT019160")}
                            options={this.consiliumReasonOptions}
                            onChange={this.onChangeConsiliumReason}
                        />
                    </Column>
                    <Column flex={"0 0 auto"}>
                        <Button
                            margin={"0 0 0 24px"}
                            onClick={this.onSubmit}
                            label={translate("TT019170")}
                        />
                    </Column>
                </Row>
            </Column>
        )
    }
}
