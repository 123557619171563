/**
 * компонент для ряботы с датой рождения
 */

import React, { Component } from "react";
import moment from "moment";
import ru from "date-fns/locale/ru";
import { getFormat, formatToMask } from "Locale/datetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import "./BirthDateInput.sass";
import PropTypes from "prop-types";
import cx from "classnames";
import styled, { withTheme } from "styled-components";
import Calendar from "GUI_MAIN/icons/Calendar";
import ReactDOM from "react-dom";
import { inject, observer } from "mobx-react";
import { overridePosition } from "GUI_MAIN/Elements/ResourceControls/DateInput";
import { SingleCalendarContainer } from "./Containers/SingleCalendarContainer";
import RequiredFieldIcon from "../RequiredFieldIcon";
import { Translator } from "eo-locale";
import { locales } from "Locale";
import { lang_ru } from "Locale/languages/ru";
import {
  controlStylesActive,
  controlStylesHover,
  labelControlStyles,
} from "../../../styles/mixins";
import _isEmpty from "lodash/isEmpty";
import DatepickerCustomHeader from "./Containers/DatepickerCustomHeader";

const DPWrapper = styled.div`
  width: ${(props) => props.mainWidth || "100%"};
  margin: ${(props) => props.mainMargin || "0"};
  ${(props) => props.mainFlexShrink && `flex-shrink: ${props.mainFlexShrink}`};
`;

const InputLabel = styled.div`
  ${(props) => labelControlStyles(props, props.isError, props.isEmpty)};
  line-height: 15px;
  display: block;
  min-height: 15px;
`;

const CalendarIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  height: 58%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  max-height: 27%;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  svg {
    height: 100%;
    fill: ${(props) =>
      props.disabled
        ? props.theme.datePickerDisabledIcon
        : props.theme.datePickerIcon};
  }
`;

const setInputBorderColor = (theme, { isError, disabled }) => {
  if (isError) return theme.controlBorderColorError;
  if (disabled) return theme.controlBorderColorDisabled;
  else return theme.controlBorderColor;
};

const DPInputWrapper = styled.div`
  position: relative;
  height: 32px;

  input {
    width: 100%;
    min-width: ${(props) => (props.inputMinWidth ? "0" : "120px")};
    padding: 6px 35px 6px 10px;
    border: 1px solid
      ${(props) =>
        setInputBorderColor(props.theme, {
          isError: props.isError,
          disabled: props.disabled,
        })} !important;
    border-radius: 4px;
    height: 32px;
    font-family: ${(props) => props.theme.OpenSansRegular};
    font-size: ${(props) => props.theme.mediumFontSize};
    color: ${(props) =>
      props.disabled
        ? props.theme.controlTextColorDisabled
        : props.theme.darkMainTextColor};
  }

  .react-datepicker-wrapper,
  .form-control,
  .react-datepicker__input-container {
    height: 100%;
  }

  input.form-control {
    border: 1px solid ${(props) => props.theme.controlBorderColor};
    box-shadow: none !important;
    outline: none;

    &:hover {
      border: 1px solid
        ${(props) =>
          !props.disabled
            ? props.theme.controlBorderColorHover
            : props.theme.controlBorderColorDisabled} !important;
    }

    &:focus,
    &:active {
      border: 1px solid
        ${(props) =>
          !props.disabled
            ? props.theme.controlBorderColorActive
            : props.theme.controlBorderColorDisabled} !important;
    }

    ::placeholder {
      color: ${(props) =>
        props.disabled
          ? props.theme.controlPlaceholderDisabledColor
          : props.theme.controlPlaceholderColor} !important;
      font-size: ${(props) => props.theme.mediumFontSize};
      padding-left: 4px;
    }
  }
`;

const DatePickerContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.whiteColor};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.mainDarkBlueColor};
`;

const InputDescription = styled.div`
  font-family: ${(props) => props.theme.OpenSansRegular};
  font-size: ${(props) => props.theme.normalFontSize};
  color: ${(props) =>
    props.isError
      ? props.theme.controlLabelColorError
      : props.theme.controlBottomDescription};
  line-height: 16px;
  margin-top: 4px;
`;

/**
 * формат для ручного ввода даты
 */
const DATE_INPUT_FORMAT = "DD.MM.YYYY";

/**
 * регулярное выражение для ручного ввода даты
 */
const DATE_INPUT_REGEXP =
  /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm;

@inject("ClientWindowStore")
@inject("HeaderStore")
@withTheme
@observer
export class BirthDateInput extends React.Component {
  constructor(props) {
    super(props);
    this.calendarInput = React.createRef();

    this.state = {
      popperPlacementCalculate: "bottom-start",
      clientWindowHeight: this.props.ClientWindowStore.clientWindowHeight,
    };
  }

  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mainMargin: PropTypes.string,
    mainWidth: PropTypes.string,
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onError: PropTypes.func,
    isClearable: PropTypes.bool,
    requiredField: PropTypes.bool,
    isErrorDescription: PropTypes.string,
    dataTestControl: PropTypes.string,
    dataTestElemId: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    isError: false,
    maxDate: new Date(),
    onBlur: () => {},
    onFocus: () => {},
    onError: () => {},
    minDate: moment().subtract(100, "years").toDate(),
    requiredField: false,
    isErrorDescription: "",
  };

  get selected() {
    return /\d{4}\-\d{2}\-\d{2}/.test(this.props.date)
      ? new Date(this.props.date)
      : "";
  }

  /**
   * перед вызовом onChange родителя
   * проверяем входит ли дата в интервал
   */
  checkAndChange = (newDate) => {
    try {
      if (newDate !== null) {
        const date = moment(newDate);
        let errMsg;
        if (date.isBefore(this.props.minDate, "day")) {
          errMsg = `Дата не может быть меньше ${this.props.minDate}`;
          this.props.onError(errMsg);
          return console.warn(errMsg);
        }
        if (date.isAfter(this.props.maxDate, "day")) {
          errMsg = `Дата не может быть больше ${this.props.maxDate}`;
          this.props.onError(errMsg);
          return console.warn(errMsg);
        }
        this.props.onChange(date.format(getFormat("DT000006")));
      } else {
        this.props.onChange("");
      }
    } catch (e) {
      console.error(e);
      this.props.onError(e);
    }
  };

  onDateChange = (newDate) => {
    return this.checkAndChange(newDate);
  };

  onInputDateChange = (event) => {
    const newDate = event.target.value;
    if (newDate.indexOf("_") === -1) {
      const newMoment = moment(newDate, getFormat("DT000001"));
      const valueIsValid = newMoment.isValid();
      if (valueIsValid) {
        return this.checkAndChange(newMoment.format());
      }
      if (this.props.isClearable && newDate === "") {
        return this.props.onChange("");
      }
    }
  };

  openDatepicker = () => this._calendar.setOpen(true);

  closeDatepicker = () => this._calendar.setOpen(false);

  componentDidMount = () => {
    const calendarInputBottom =
      this.calendarInput.current.getBoundingClientRect().bottom;

    if (this.state.clientWindowHeight - calendarInputBottom < 300) {
      this.setState({
        popperPlacementCalculate: "top-start",
      });
    } else {
      this.setState({
        popperPlacementCalculate: "bottom-start",
      });
    }

    window.addEventListener("resize", this.listenerResize);
  };

  listenerResize = () => {
    const calendarInputBottom =
      this.calendarInput.current.getBoundingClientRect().bottom;
    this.setState({
      clientWindowHeight: this.props.ClientWindowStore.set(),
    });

    if (
      this.props.ClientWindowStore.clientWindowHeight - calendarInputBottom <
      300
    ) {
      this.setState({
        popperPlacementCalculate: "top-start",
      });
      const htmlCollection = document.getElementsByClassName(
        "react-datepicker-popper"
      );
      if (htmlCollection.length) {
        htmlCollection[0]["style"]["marginTop"] = "-10px";
      }
    } else {
      this.setState({
        popperPlacementCalculate: "bottom-start",
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.listenerResize);
  }

  onKeyPress = (e) => {
    this.props.onKeyDown && this.props.onKeyDown(e);
    if (e.key === "Enter") {
      this.closeDatepicker();
    } else if (e.keyCode == "9") {
      this.closeDatepicker();
    }
  };

  render() {
    const {
      preview,
      label,
      mainWidth,
      mainMargin,
      disabled,
      noID,
      inputMinWidth,
      isError,
      calendarPlacement,
      requiredField,
      isClearable,
      onKeyDown,
      HeaderStore,
      mainFlexShrink,
      inputDescription,
      dataTestControl,
      dataTestElemId,
    } = this.props;
    const { popperPlacementCalculate } = this.state;

    const errorPrefix = isError ? " form-control__isError " : "";

    const DatePickerContainer = ({ className, children }) => {
      return (
        <DatePickerContainerWrapper>
          <SingleCalendarContainer className={"datepicker-container"}>
            {children}
          </SingleCalendarContainer>
        </DatePickerContainerWrapper>
      );
    };

    const CalendarIcon = () => {
      const Icon = (
        <CalendarIconWrapper
          disabled={preview}
          className={"datepicker-indicator"}
          onClick={this.openDatepicker}
        >
          <Calendar />
        </CalendarIconWrapper>
      );

      if (disabled) {
        return Icon;
      }
      if (!this.selected) {
        return Icon;
      }
      if (this.selected && this.props.requiredField) {
        return Icon;
      }
    };

    const CalendarPopperContainer = ({ children }) =>
      children
        ? ReactDOM.createPortal(
            React.cloneElement(children, {
              className: "birthday-datepicker-portal react-datepicker-popper",
            }),
            document.body
          )
        : null;

    const translator = new Translator(HeaderStore.locale, locales);
    const placeholderText =
      this.props.placeholder && lang_ru.messages[this.props.placeholder]
        ? translator.getMessageById(this.props.placeholder)
        : this.props.placeholder;

    return (
      <DPWrapper
        id={noID ? "" : "birth-date-input"}
        mainWidth={mainWidth}
        mainMargin={mainMargin}
        mainFlexShrink={mainFlexShrink}
        data-test-control={dataTestControl}
        data-test-element-id={dataTestElemId}
      >
        {label || label === "" ? (
          <InputLabel
            label={label}
            isError={isError}
            isEmpty={_isEmpty(this.date)}
          >
            {label}
            {requiredField && !disabled && <RequiredFieldIcon />}
          </InputLabel>
        ) : null}
        <DPInputWrapper
          ref={this.calendarInput}
          disabled={disabled}
          inputMinWidth={inputMinWidth}
          isError={isError}
        >
          <DatePicker
            placeholderText={placeholderText}
            readOnly={this.props.readOnly}
            id={noID ? "" : this.props.id}
            selected={this.selected}
            className={cx(
              { "form-control": !this.props.readOnly },
              errorPrefix + this.props.className,
              {
                "form-control-plaintext": this.props.readOnly,
              }
            )}
            showMonthDropdown
            showYearDropdown
            isClearable={this.selected && !requiredField && !disabled}
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            onBlur={this.props.onBlur}
            onFocus={() => this.props.onFocus}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            locale={HeaderStore.locale}
            disabled={preview || disabled}
            dateFormat={getFormat("DT000007")}
            popperContainer={CalendarPopperContainer}
            popperPlacement={calendarPlacement || popperPlacementCalculate}
            calendarContainer={DatePickerContainer}
            // onKeyDown={onKeyDown}
            KeyboardIconProps={{
              onKeyDown: onKeyDown,
            }}
            customInput={
              <CustomDataPickerInput
                type="text"
                onChangeValue={(val) => this.onInputDateChange(val)}
                onKeyDownFunc={this.onKeyPress}
                mask={formatToMask(getFormat("DT000007"))}
              />
            }
            onChange={this.onDateChange}
            onChangeRaw={(e) => e.preventDefault()}
            ref={(c) => (this._calendar = c)}
            // closeOnScroll={(e) => closeOnScroll(e, this._calendar)}
            onCalendarOpen={this.listenerResize}
            onCalendarClose={this.props.onBlur ? this.props.onBlur : null}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <DatepickerCustomHeader
                date={date}
                changeYear={changeYear}
                changeMonth={changeMonth}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                increaseMonth={increaseMonth}
                decreaseMonth={decreaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
          />
          {!(this.selected && !requiredField && !disabled) && (
            <CalendarIconWrapper
              disabled={preview || disabled}
              onClick={() => {
                this.openDatepicker();
                this.props.onFocus ? this.props.onFocus() : () => {};
              }}
              className={"datepicker-indicator"}
            >
              <Calendar
                fill={
                  disabled
                    ? this.props.theme.datePickerDisabledIcon
                    : this.props.theme.datePickerIcon
                }
              />
            </CalendarIconWrapper>
          )}
        </DPInputWrapper>
        {inputDescription && (
          <InputDescription isError={isError}>
            {inputDescription}
          </InputDescription>
        )}
      </DPWrapper>
    );
  }
}

class CustomDataPickerInput extends Component {
  render() {
    const propsClone = { ...this.props };
    delete propsClone.onChangeValue;
    delete propsClone.onKeyDownFunc;

    return (
      <MaskedInput
        {...{
          ...propsClone,
          onChange: (val) => this.props.onChangeValue(val),
          onKeyDown: (e) => this.props.onKeyDownFunc(e),
        }}
      />
    );
  }
}
