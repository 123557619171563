import React, {PureComponent} from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import styled, {withTheme} from "styled-components";
import {controlStyles, controlStylesActive, controlStylesHover, labelControlStyles} from "styles/mixins";
import {inject, observer} from "mobx-react";
import {Translator} from "eo-locale";
import {locales} from "Locale";
import {lang_ru} from "Locale/languages/ru";
import { getFormat } from "Locale/datetime";
import RequiredFieldIcon from "../RequiredFieldIcon";
import _isEmpty from "lodash/isEmpty";
import SelectClear from "GUI_MAIN/icons/SelectClear";

const setInputBorderColor = (theme, {disabled, isError}) => {
  if(disabled) return theme.inputDisabledBorder;
  if(isError) return theme.errorInputBorderColor;
  else return theme.inputBorderColor;
};

const setPadding = (paddingInput, isClearable, value) => {
  if(paddingInput) return paddingInput;
  if(isClearable) return '0px 30px 0px 10px';

  return '10px 12px';
};

const MaskedInputWrapper = styled.div`
  position: relative;
  margin: ${props => props.mainMargin || "0"};
  width: ${props => props.mainWidth};

  input {
    height: 32px;
    ${props => controlStyles(props, {disabled: props.disabled, isError: props.isError})};
    outline: none;
    transition: ${props => props.theme.mainTransition};
    padding: ${props => setPadding(props.paddingInput, props.isClearable, props.value)};
    ${props => !props.disabled && "cursor: text"};

    &:hover{
      ${props => controlStylesHover(props, props.disabled)};
      cursor: text;
    }

    &:focus, &:active {
      ${props => controlStylesActive(props, props.disabled)};
      box-shadow: none;
      cursor: text;
    }

    &:invalid {
      box-shadow: ${(props) => props.disableInvalid ? 'none' : 'initial'};
    }

    ::-webkit-input-placeholder {
      padding-left: 4px;
    }

    ::placeholder{
      padding-left: 3px;
    }
  }
`;

const InputLabel = styled.span`
  ${props => labelControlStyles(props, props.isError, props.isEmpty)};
  display: block;
`;

const IconWrapper = styled.div`
  position: absolute;
  height: 32px;
  bottom: 0;
  right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg path {
    transition: fill 150ms;
  }

  &:hover {
    svg path {
      fill: hsl(0,0%,60%);
    }
  }
`;

@inject('HeaderStore')
@withTheme
@observer
export class MaskedInput extends PureComponent {

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  onClear = (e) => {
    const { onChange, onBlur} = this.props;
    this.inputRef.value = "";
    onChange ? onChange("") : null;
    onBlur ? onBlur(e) : null;
  };
  render() {

    const {
      mainMargin,
      inputHeight,
      HeaderStore,
      inputPlaceholder,
      mainWidth,
      isError,
      disabled,
      paddingInput,
      label,
      requiredField,
      readOnly,
      mask,
      value,
      onBlur,
      onFocus,
      onKeyDown,
      size,
      onChange,
      className,
      isClearable,
      dataTestControl,
      dataTestElemId,
    } = this.props;

    const translator = new Translator(HeaderStore.locale, locales);
    const codePlaceholder = inputPlaceholder;
    const placeholderText =  codePlaceholder && lang_ru.messages[codePlaceholder] ?
      translator.getMessageById(codePlaceholder) : inputPlaceholder;

    return (
      <MaskedInputWrapper
        mainMargin={mainMargin}
        inputHeight={inputHeight}
        mainWidth={mainWidth}
        isError={isError}
        disabled={disabled}
        paddingInput={paddingInput}
        isClearable={isClearable}
        value={value}
        data-test-control={dataTestControl}
        data-test-element-id={dataTestElemId}
      >
        {
          label &&
          <InputLabel label={label} isError={isError} isEmpty={_isEmpty( value ) || !value.trim().length}>
            {label}
            {requiredField && !disabled &&
              <RequiredFieldIcon/>
            }
          </InputLabel>
        }
        <InputMask
          ref={(ref) => this.inputRef = ref}
          readOnly={readOnly}
          mask={mask}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholderText}
          onKeyDown={onKeyDown}
          className={cx(
            {'form-control': !readOnly},
            className,
            {
              [`form-control-${size}`]: size
            },
            {'form-control-plaintext': readOnly}
          )}
          onChange={(e) => onChange(e.target.value)}
        />
        {
          !disabled && isClearable && value &&
            <IconWrapper onClick={(e) => this.onClear(e)}>
              <SelectClear/>
            </IconWrapper>
        }
      </MaskedInputWrapper>
    )
  }
}

export const SnilsInput = (props) => <MaskedInput mask="999-999-999-99" {...props} />;

export const PhoneInput = (props) => <MaskedInput mask="+7 999-999-99-99" {...props} />;

export const DateRangeInput = (props) => props.test ? <MaskedInput mask={getFormat("DT000008")} {...props}/> : <MaskedInput mask={getFormat("DT000008") + " - " + getFormat("DT000008")} {...props}/>

export const DateInput = (props) => <MaskedInput mask="99.99.9999" {...props} />

export const DateInputWithLocaleFormat = (props) => <MaskedInput mask={getFormat("DT000008")} {...props} />
