import React, {PureComponent} from 'react';
import styled from 'styled-components';
import HeaderLogo from './HeaderLogo/Index';
import {inject, observer} from 'mobx-react';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import InputWithIcon from "GUI_MAIN/Elements/InputWithIcon/index";
import Input from 'GUI_MAIN/Elements/Input/index'
import UserPanel from "./UserPanel/UserPanel";
import {StyledCheckbox} from '../../Elements/StyledCheckbox';
import {getSettings} from "GUI_MAIN/Utils/localStorageSettings";
import {onChangePatientsHideDataSettings} from "GUI_MAIN/Utils/localStorageSettings";
import Close from "GUI_MAIN/icons/Close";
import { AuthStore } from 'MODEL_STORE/DataSource/Stores/AuthStore';
import {
    ACCESS_RIGHT_ACTION,
    ACCESS_RIGHT_OBJECT,
    ACCESS_RIGHT_INCLUDES,
} from "MODEL_STORE/DataSource/Constants/user_access_rights";
import { Notifications } from "./Notifications";
import {Link} from "react-router-dom";
import {EOLocale, Translator} from "eo-locale";
import {locales} from "Locale";
import {reaction} from "mobx";

const HeaderWrapper = styled.div`
    width: 100%;
    height: 64px;
    background-color: ${props => props.theme.headerBG};
    position: fixed;
    z-index: 5;
`;

const Content = styled.div`
    max-width: 1511px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
`;

const HeaderTitle = styled.div`
  flex-grow: 1;
  margin: 0 20px 0 25px;
  font-family: ${props => props.theme.OpenSansSemiBold};
  font-size: 18px;
  height: 24px`;

const Item = styled.div`
  width: ${props => props.width ? props.width : 'auto'};
  height: ${props => props.height ? props.height : 'auto'};
  background-color: ${props => props.bgColor ? props.bgColor : 'none'};
  transition: width 0.5s;
  position: relative;
  display: flex;
  overflow-y: hidden;
  align-items: center;
  margin: ${props => props.margin ? props.margin : 0 };
  border-radius: 4px`;

const InputWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  `;

const SettingsWrapper = styled.div`
    color: white;
    margin-right: 30px;`;

const CloseWrapper = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  svg{
    width: 100%;
    height: 100%;
  }
  margin-right: 32px;
`;

const LinkWrapper = styled.div`
  a, span{
    color: ${props => props.theme.defaultBreadcrumbsColor};
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
  }

  a{
    text-decoration: underline;
  }

  &:hover{
    a, span{
      color: ${props => props.theme.whiteColor};
    }
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
`;

@inject('HeaderStore')
@observer
export class Header extends PureComponent{

    state={
        inputValue: '',
        isInputShow: false,
        isFocusInput: false,
        hideDataCurrentPatient: false,
        isIconActive: false,
        backHome: false
    };

    constructor(props) {
        super(props);
        const patientId = HeaderStore.patientId;
        if (patientId) this.getPatientIdFromSettings(patientId);
    }

    /**
     * виден ли поиск uptodate?
     */
    get isUpToDateVisible() {
        if (!AuthStore.currentUserData.isRoot())
            return AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.HEADER_UPTODATE, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL);
        return true;
    }

    getPatientIdFromSettings = (patientId) => {
        const settings = getSettings();
        this.setState({
            hideDataCurrentPatient: settings.presentation.patientsHideData.indexOf(patientId) !== -1
        })
    };

    onChangeSettings = () => {
        const patientId = HeaderStore.patientId;
        onChangePatientsHideDataSettings(patientId);
        this.getPatientIdFromSettings(patientId);
    };

    onCloseWindow = () => {
        const translator = new Translator(HeaderStore.locale, locales);
        if (confirm(translator.getMessageById("SS014400"))) {
            close();
        }
    };

    componentDidMount() {
        reaction(() => HeaderStore.patientId, () => {
            const patientId = HeaderStore.patientId;
            if (patientId) this.getPatientIdFromSettings(patientId);
        })
    }

    hasClosePermission = () => {
        return (window.opener != null || window.history.length >= 1)
    };

    render() {
        const {stateIconMenu} = this.props;
        const {inputValue, isInputShow, isFocusInput, hideDataCurrentPatient, isIconActive, backHome} = this.state;

        const presentationMod = HeaderStore.presentationMod;
        const patientId = HeaderStore.patientId;

        const breadcrumbs = HeaderStore.breadcrumbs;
        return(
            <HeaderWrapper>
                <Content>
                    <HeaderLogo showMenu={HeaderStore.showLeftMenu}/>
                    <HeaderTitle>
                        <Breadcrumbs>
                            {
                                breadcrumbs &&
                                breadcrumbs.map((item, key) => {
                                    return <LinkWrapper key={key}>
                                            <Link
                                              to={item.path}
                                              onClick={() => {HeaderStore.showLeftMenu = true; this.setState({backHome: true});}}
                                              data-test-control={'breadcrumbs-link'}
                                              data-test-element-id={item.path}
                                            >
                                                <EOLocale.Text id={item.name}/>
                                            </Link>
                                            <span>&nbsp;/&nbsp;</span>
                                        </LinkWrapper>
                                })
                            }
                            {
                                !! (presentationMod && hideDataCurrentPatient) ?
                                    <span>{HeaderStore.titlePrivate}</span> :
                                    HeaderStore.title !== "" && <EOLocale.Text { ...HeaderStore.headerParams } id={HeaderStore.title} />
                            }
                        </Breadcrumbs>
                    </HeaderTitle>

                    {
                        presentationMod && patientId &&
                        <>
                            <SettingsWrapper>
                                <StyledCheckbox
                                    label={<EOLocale.Text id={'SS014378'}/>}
                                    id={'settingHideName'}
                                    value={hideDataCurrentPatient}
                                    onChange={() => this.onChangeSettings(patientId)}
                                    isDarkenBG
                                    dataTestControl={'setting-hide-name'}
                                />
                            </SettingsWrapper>
                            {
                                this.hasClosePermission() &&
                                <CloseWrapper onClick={this.onCloseWindow} data-test-control={'presentation-close'}>
                                    <Close fill={'white'} />
                                </CloseWrapper>
                            }

                        </>
                    }

                    {
                        !presentationMod &&
                        <>
                            {/*Иконка "Уведомления"*/}
                            <Notifications/>

                            <Item margin={'0 24px 0 17px'}>
                                <UserPanel/>
                            </Item>
                        </>
                    }
                </Content>
            </HeaderWrapper>
        )
    }
}
