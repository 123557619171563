import React from 'react';
import styled from "styled-components";
import Button from "../Button/index";
import CloseCross from "GUI_MAIN/icons/CloseCross";

import Scrollbar from "react-scrollbars-custom";
import {errorBoundary} from "../../ErrorBoundary/ErrorBoundary";
import {observer} from "mobx-react";

const PopupBasicWrapper = styled.div`
  position: fixed !important;
  //height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(${props => props.theme.blackColorRGB}, 0.18);
  z-index: ${props => props.zIndex ? props.zIndex : "10"}; //9999;
  cursor: auto;
  ${props => props.hidePopup ?  "display: none !important;" : "display: flex !important;"};
`;

const setWidth = (size) => {
    switch (size) {
        case 'small':
            return '400px';
        case 'confirmPopupSize':
        case 'sessionEnd':
            return '460px';
        case 'documentEdit':
        case 'treatmentHistory':
        case 'conditionAdd':
            return '600px';
        case 'medicalSize':
            return '650px';
        case 'saveTemplate':
        case 'editDrug':
            return '700px';
        case 'editDisease':
        case 'editDiseaseNew':
        case 'addConsiliumParticipant':
            return '860px';
        case 'confirmPatientInfoSize':
            return '300px';
        case 'planDefinitionHistory':
        case 'delete':
            return '500px';
        case 'diseaseInfo':
            return '860px';
        case 'replacementPractitionerHistory':
            return '900px';
        case 'emiasDialog':
            return '1000px';
        case 'allDiseasesPopupSize':
        case 'participantsByOrder':
        case 'replacementPractitioner':
        case 'addTreatmentPlanByTemplate':
        case 'moveServiceRequest':
            return '1200px';
        case 'journalGospitalization':
            return '1230px';
        case 'hugeSize':
        case 'hugeSizeAuto':
            return '83%';
        case 'fullSize':
            return '98%';
        default:
            return '800px';
    }
};

const setHeight = (size) => {
    switch (size) {
        case 'delete':
            return 'auto';
        case 'fullSize':
        case 'hugeSize':
        case 'allDiseasesPopupSize':
        case 'addTreatmentPlanByTemplate':
            return '98%';
        // case 'replacementPractitioner':
        //     return '698px';
        //case 'editDisease':
        //return '90%';
        case 'addConsiliumParticipant':
            return '720px';
        default:
            return 'auto';
    }
};

const setMaxWidth = (size) => {
    switch (size) {
      case 'addTreatmentPlanByTemplate':
          return '98vw';
      default:
          return 'unset'
    }
};

const setMaxHeight = (size) => {
    switch (size) {
        case 'diseaseInfo':
            return '780px';
        default:
            return '98vh';
    }
}

const PopupBasicContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => setWidth(props.size)};
  height: ${props => setHeight(props.size)};
  max-height: ${props => setMaxHeight(props.size)};
  max-width: ${props => setMaxWidth(props.size)};
  overflow: ${props => {if(props.noBodyScroll) return 'hidden'}};
  word-break: break-word;
  background-color: ${props => props.theme.whiteColor};
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 4px;
`;

const PopupBasicHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  background-color: ${props => props.theme.modalHeaderBlueBG};
  color: ${props => props.theme.whiteColor};
  font-size: ${props => props.theme.bigFontSize};
  font-family: ${props => props.theme.OpenSansBold};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ButtonIcon = styled.span`
  color: ${props => props.theme.whiteColor};
`;

const PopupBasicTitle = styled.div``;

const PopupBasicCloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
`;

const PopupBasicBody = styled.div`
  display: flex !important; //${props => {if(props.noBodyScroll) return 'flex'}};
  flex-direction: column;
  flex-grow: ${props => {if(props.noBodyScroll) return '1'}};
  //height: calc(100% - 48px);
  //height: 100%;
  width: 100%;
  // ${props => {if(!props.noBodyScroll) return 'max-height: 80vh'}};
  ${props => props.overflowHidden && "overflow: hidden;"};
  padding: ${props => props.padding || '15px 16px 16px 16px'};
  background-color: ${props => props.bodyBG || props.theme.whiteColor};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  >div:first-child{
    width: 100%;
    ${props => {if(props.noBodyScroll) return 'height: 100%;'}};
  }
`;

const PopupBasicBottom = styled.div`
  display: flex;
  justify-content: ${props => props.bottomActionsPosition || 'flex-end'};
  position: relative; //absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  pointer-events: none;
  border-top: 1px solid ${props => props.theme.topBorderModalBottomColor};
  z-index: 0;

  &>div{
    pointer-events: auto;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 10;
`;

const FilterBG = styled.div`
  display: none;
`;

@observer
@errorBoundary(null, null, null, null, "PopupBasic")
export class PopupBasic extends React.Component {

    render() {
        const {
            title,
            onClose,
            children,
            size,
            bottomActions,
            padding,
            bodyBG,
            overflowHidden,
            bottomActionsPosition,
            btnCloseHidden,
            noBodyScroll,
            zIndex,
            hasRequiredFields,
            hidePopup,
            popupTestControl,
            closeButtonTestControl,
        } = this.props;

        const minScrolledHeight = () => {
            switch(size){
                case('fullSize'):
                    return '100%';
                default:
                    return '150'
            }
        };

        return (
            <PopupBasicWrapper zIndex={zIndex} hidePopup={hidePopup}>
                <PopupBasicContent
                    className="popupBasic-content "
                    size={size}
                    noBodyScroll={noBodyScroll}
                    data-test-control={popupTestControl}
                >
                        <PopupBasicHeader>
                            <PopupBasicTitle>
                                {title}
                            </PopupBasicTitle>
                            <PopupBasicCloseButtonWrapper>
                                {
                                    btnCloseHidden ? null :
                                        <Button type={'icon'}
                                                onClick={onClose}
                                                label={<CloseCross/>}
                                                dataTestControl={closeButtonTestControl}/>
                                }
                            </PopupBasicCloseButtonWrapper>
                        </PopupBasicHeader>
                        {
                            noBodyScroll ?
                                <PopupBasicBody padding={padding} bodyBG={bodyBG} overflowHidden={overflowHidden} noBodyScroll={noBodyScroll}>
                                    {children}
                                </PopupBasicBody>
                                :
                                <Scrollbar
                                    style={{width: "100%"}}
                                    noScrollX={true}
                                    translateContentSizeYToHolder
                                    contentProps={{
                                        renderer: props => {
                                            const { elementRef, ...restProps } = props;
                                            return <PopupBasicBody
                                                {...restProps} ref={elementRef}
                                                padding={padding}
                                                bodyBG={bodyBG}
                                                overflowHidden={overflowHidden}
                                                className={'ScrollbarsCustomPopup'}
                                            >
                                                {children}
                                            </PopupBasicBody>
                                        }
                                    }}
                                >

                                </Scrollbar>
                        }
                        {
                            bottomActions &&
                            <PopupBasicBottom bottomActionsPosition={bottomActionsPosition}>
                                <Buttons>{bottomActions}</Buttons>

                                {/*<Filter>*/}
                                {/*    <FilterBG>*/}
                                {/*        <FilterBGIcon/>*/}
                                {/*    </FilterBG>*/}
                                {/*</Filter>*/}
                            </PopupBasicBottom>
                        }
                </PopupBasicContent>
            </PopupBasicWrapper>
        );
    }
}
