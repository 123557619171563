import React, {PureComponent} from 'react';
import _get from "lodash/get";
import ArrowBorder from "../../../../icons/ArrowBorder";
import styled from "styled-components";
import Scrollbar from "react-scrollbars-custom";

const DownArrow = styled.div`
  display: flex;
`;

const SelectDropdownContainer = styled.div`
  display: inline-block;
  width: ${props => props.selectWidth + 'px' || '100px'};
  margin-right: 4px;
  height: 32px;
  position: relative;
`;

const SelectDropdown = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  width: ${props => props.selectWidth + 10 + 'px' || '110px'};
  padding: 8px 0;
  left: -5px;
  top: 39px;
  border-radius: 4px;
  background-color: ${props => props.theme.whiteColor};
  box-shadow: 0px 3px 18px 0px #00000024;
`;

const SelectView = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-family: ${props => props.theme.OpenSansSemiBold};
  font-size: ${props => props.theme.mediumFontSize};
  padding: 7px 8px 7px 10px;
  line-height: 1.3;
  cursor: pointer;
  border-radius: 4px;

  &:hover{
    background-color: ${props => props.isOpen ? props.theme.whiteColor : props.theme.datePickerDayHoverBG};
    border: 1px solid ${props => props.isOpen ? props.theme.controlBorderColorActive : props.theme.whiteColor};

  }

  &{
    background-color: ${props => props.theme.whiteColor};
    border: 1px solid ${props => props.isOpen ? props.theme.controlBorderColorActive : props.theme.whiteColor}
  }
`;

const SelectOption = styled.div`
  font-family: ${props => props.isSelected ? props.theme.OpenSansSemiBold : props.theme.OpenSansRegular};
  background-color: ${props => props.isSelected ? props.theme.optionBgSelected : props.theme.whiteColor};
  font-size: ${props => props.theme.mediumFontSize};
  line-height: 20px;
  padding: 6px 16px;
  text-align: left;
  cursor: pointer;

  &:hover{
    background-color: ${props => props => props.isSelected ? props.theme.optionBgSelected : props.theme.optionBgHover};
  }
`;

class NativeSelect extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isOptionSelected: []
        }

        this.dropdownMenu = React.createRef()
    }

    onDropdownListOpen = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onChange = (option) => {
        const {onChange} = this.props;
        if (onChange) onChange(option);
        this.setState({ isOpen: false });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const menu = this.dropdownMenu.current;

        if (!menu || !menu.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const {isOpen} = this.state;
        const {
            options,
            value,
            selectWidth,
            dataTestControl
        } = this.props;

        return (
            <SelectDropdownContainer
                selectWidth={selectWidth}
            >
                {
                    isOpen ?
                            <SelectDropdown
                                selectWidth={selectWidth}
                                ref={this.dropdownMenu}
                            >
                                <Scrollbar translateContentSizesToHolder
                                style={{maxHeight: '200px'}}>
                                {
                                    options.map((option, i) =>
                                        <SelectOption
                                            key={option.value}
                                            isSelected={_get(value, "value") === option.value}
                                            onClick={() => this.onChange(option)}>
                                            {option.name}
                                        </SelectOption>
                                    )
                                }
                                </Scrollbar>
                            </SelectDropdown>
                         : null
                }
                <SelectView onClick={this.onDropdownListOpen}
                            isOpen={isOpen}
                            data-test-control={dataTestControl}
                >
                    <span>{_get(value, 'name')}</span>
                    <DownArrow>
                        <ArrowBorder rotateArrow={isOpen ? 180 : 0}
                                     color={isOpen ? '#1B75BB' : '#A1A4B1'}/>
                    </DownArrow>
                </SelectView>
            </SelectDropdownContainer>
        );
    }
}

NativeSelect.propTypes = {};

export default NativeSelect;
