import React from "react";
import PropTypes from "prop-types";
import styledMap from "styled-map";
import Eye from "GUI_MAIN/icons/Eye";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import styled, { withTheme } from "styled-components";
import { setLineHeight } from "GUI_MAIN/Utils/setLineHeight";
import { StyledReactTooltip } from "GUI_MAIN/Elements/StyledReactTooltip";
import { ToasterStore } from "MODEL_STORE/DataSource/Stores/ToasterStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import _get from "lodash.get";
import ReactTooltip from "react-tooltip";
import {EOLocale} from "eo-locale";
import { config } from 'config';

const IconWrapper = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    border-left: 5px solid ${props => props.unread ? props.theme[props.type === "task" ? "notificationLeftBorderColor" : "mainRedColor"] : "transparent"};
    padding: 6px 12px;
    cursor: pointer;

    &:hover{
        background-color: ${props => props.theme.notificationItemHoverBG};

        ${IconWrapper}{
            display: flex;
        }
    }
`;

const Icons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 24px;
    margin-left: 3px;
    padding: 7px 0;
`;

const IconWrapperEye = styled(IconWrapper)`
    &:hover{
        svg path{
            stroke: ${props => props.theme.mainDarkBlueColor};
        }
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1;
`;

const Row = styled.div`
    &:not(:last-child){
        margin-bottom: 4px;
    }
`;

const Label = styled.div`
    font-family: ${styledMap`
        bold: ${props => props.theme.OpenSansBold};
        semibold: ${props => props.theme.OpenSansSemiBold};
        default: ${props => props.theme.OpenSansRegular};
    `};
    font-size: ${styledMap`
        medium: ${props => props.theme.mediumFontSize};
        min: ${props => props.theme.minFontSize};
        default: ${props => props.theme.normalFontSize};
    `};
    line-height: ${styledMap`
        medium: 20px;
        min: ${props => setLineHeight(props.theme.minFontSize, 1)};
        default: ${props => setLineHeight(props.theme.normalFontSize, 1.14)};
    `};
    color:  ${styledMap`
        red: ${props => props.theme.mainRedColor};
        blue: ${props => props.theme.mainDarkBlueColor};
        light: ${props => props.theme.lightNotificationText};
        default: ${props => props.unread ? props.theme.darkMainTextColor : "#8B90A0"};
    `};
`;

const TypeLabel = styled(Label)`
    color: ${props => props.type === "task" ? props.theme.mainDarkBlueColor : "#727279"};
`;

const Link = styled.a`
    color: ${ props => props.theme.mainDarkBlueColor };
    text-decoration: underline;
`;

/**
 * задание - task, уведомление - notification. Указывается для цвета полоски слева и текста "дата+тип нотификации"
 * флаг прочитано - указывается для отображения полоски слева и для font-weight у блока "дата+тип нотификации"
 */

@withRouter
@withTheme
@observer
export class NotificationItem extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        actions: PropTypes.object.isRequired,
        notification: PropTypes.object.isRequired,
    }

    /**
     * отмечаем нотификацию как прочитанную
     */
    onRead = async () => {
        ReactTooltip.hide();
        try {
            await this.props.actions.readOneNotification(this.props.notification.id);
        } catch (error) {
            console.error(`При изменении статуса нотификации ${this.props.notification.id} произошла ошибка`, _get(error, 'response.data', error));
        }
    }

    /**
     * 1. отмечаем нотификацию как прочитанную
     * 2. скрываем попап с нотификациями
     * 3. переходим по ссылке из нотификации
     */
    onClick = async () => {
        // лучше это делать на бэке
        // Если у нас уведомление по ресурсу Appointment, то переводим в режим редактирования
        // TODO Вероятно тут можно будет проверить права
        const resourceType = _get(this.props.notification, 'resource.context.resource.resourceType', '');
        let modificator = '';
        let standPath = ''
        if (resourceType === 'ServiceRequest') {
            standPath = '/tb'
            modificator = '#comments';
        }
        else if (resourceType === 'Appointment') {
            standPath = '/tb';
        }
        else if (resourceType === 'MedicationAdministration') {
            standPath = '/mm'
            modificator = '#treatmentStatus';
        }
        else if (resourceType === 'CarePlan') {
            standPath = '/mm';
        }

        try {
          await this.props.actions.readOneNotification(this.props.notification.id);
        } catch (error) {
          console.error(`При изменении статуса нотификации ${this.props.notification.id} произошла ошибка`, _get(error, 'response.data', error));
          // const translator = new Translator(HeaderStore.locale, locales);
          // ToasterStore.error( translator.translate("__MIRA_2771_01", { notification_id: this.props.notification.id }));
        }

        const baseUrl = config.get('EXTERNAL_ADDRESS').replace(/\/+$/, '').replace(/\/tb$|\/mm$/, '')
        this.props.actions.hideNotifications();
        const actionPath = baseUrl + standPath + this.props.notification.actionPath + modificator;
        if (resourceType === 'MedicationAdministration' && this.props.location.pathname === this.props.notification.actionPath) {
            this.props.history.push('/');
            setTimeout(() => this.props.history.push(actionPath))
        } else {
            window.location.replace(actionPath);
        }

    }

    /**
     * при клике на ссылку в нотификации
     * если ссылка ведет на вкс, то (https://jira.mmdx.ru/browse/FCSPOK-544):
     * Фронтенд отправляет запрос Бэкенду на подключение к комнате ВКС,
     * методом GET /api/tb/appointments/{AppointmentId}/vks/$redirect,
     */
    handleChildElementClick = (event) => {
        event.stopPropagation();
        const parts = event.target.href.split("/");
        if ( parts.includes("vks") ) {
            event.preventDefault();
            const identifier = parts[parts.length - 3];
            basicRequestGet(`api/tb/appointments/${identifier}/vks/$redirect`).then(response => {
                return window.open(response.data.vksUrl);
            }).catch(error => {
                console.error(error);
                if ( error.response && error.response.status ) {
                    if ( error.response.status >= 400 && error.response.status <= 499 ) {
                        if ( error.response.data && Array.isArray(error.response.data) ) {
                            return error.response.data.forEach(entry => {
                                ToasterStore.error(entry.message);
                            })
                        }
                    }
                }
                return ToasterStore.error("При получении ссылки на ВКС произошла ошибка");
            });
        }
    }


    prepareText = (text) => {

        if (text.includes('[Ссылка]')) {
              const link = text.match(/(\(https?:\/\/[^"\s&]+)/g)
              if (link && link.length) {
                  text = text.replace(link, '')
                  return (
                      <>
                          {text.split('[Ссылка]')[0]}
                          <Link
                            target="_blank"
                            href={link[0].replace('(', '').replace(')', '')}
                            onClick={this.handleChildElementClick}
                          >
                            Ссылка
                          </Link>
                          {text.split('[Ссылка]')[1]}
                      </>
                  )
              }
        }

        return text;
    }

    /**
     * отрисовка компонента
     */
    render() {
        const {
            theme,
            notification,
            itemKey
        } = this.props;

        return (
            <Wrapper type={notification.code} unread={notification.isUnread}>
                <Inner data-tip
                       data-for={'action-name' + itemKey}
                       onClick={this.onClick}
                       data-test-control={'notifications-element'}
                       data-test-element-id={this.props.notification.id}>
                    <Label bold medium unread={notification.isUnread}>
                        { notification.title }
                    </Label>
                    <Row>
                        <Label medium unread={notification.isUnread}>
                            {
                                notification.text.includes('[Ссылка]') ?
                                this.prepareText(notification.text)
                                :
                                notification.text
                            }
                        </Label>
                    </Row>
                    <Row>
                        <TypeLabel min={notification.code === "task" ? '1' : 0}
                                   medium={notification.code !== "task" ? '1' : 0}
                                   type={notification.code}>
                            { notification.dateTime }
                            &nbsp;
                            { notification.typeName }
                        </TypeLabel>
                    </Row>
                </Inner>
                { /* если нотификация не прочитана - даем возможность отметить как прочитанное */}
                { notification.isUnread &&
                    <Icons>
                        <IconWrapperEye data-tip data-for={'read' + itemKey} data-place={"left"} onClick={this.onRead}>
                            <Eye stroke={theme.darkMainTextColor}/>
                        </IconWrapperEye>
                    </Icons>
                }
                <StyledReactTooltip
                    type="light"
                    className={"light light-simple shadow max-width-400"}
                    id={'action-name' + itemKey}
                >
                    {notification.actionName}
                </StyledReactTooltip>
                <StyledReactTooltip
                    type="light"
                    className={"light light-simple shadow max-width-400"}
                    id={'read' + itemKey}
                >
                    <EOLocale.Text id={'TT019940'} />
                </StyledReactTooltip>
            </Wrapper>
        );
    }
}
