import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import styled from "styled-components";
import Scrollbar from "react-scrollbars-custom";
import Preloader from "GUI_MAIN/Elements/Preloader";
import { NotificationItem } from "./NotificationItem";
import { NotificationFilters } from "./NotificationFilters";
import FilterSimpleIcon from "GUI_MAIN/icons/FilterSimpleIcon";
import { EOLocale } from "eo-locale";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${props => props.allNotifications ? '610px' : 'calc(100vh - 102px)'}
`;

const AllItemsButton = styled.div`
    display: flex;
    height: 50px;
    padding: 16px;
    border-top: 1px solid ${props => props.theme.borderColor};
    cursor: default !important;
    background-color: #FAFAFC;
`;

const ButtonText = styled.div`
    display: flex;
    align-self: center;
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansSemiBold};
    color: ${props => props.theme.mainDarkBlueColor};
    line-height: 16px;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
`;

const FilterBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 12px 95px 12px 12px;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    color: ${props => props.theme.notificationFiltersColor};
    line-height: 18px;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    background-color: ${props => props.isFiltersOpen && props.theme.notificationFilterIconActiveColor};

    svg{
        width: 24px;

        path{
            stroke: ${props => props.isFiltersOpen && props.theme.whiteColor};
        }
    }

    &:hover, &:focus-within{
        background-color: ${props => props.theme.notificationFilterIconActiveColor};

        svg path{
            stroke: ${props => props.theme.whiteColor};
        }
    }
`;

const FilterButton = styled.div`
    color: ${props => props.disabled ? props.theme.notificationFiltersColor : props.theme.mainDarkBlueColor};
    font-family: ${ props => props.theme.OpenSansSemiBold };
    ${props => !props.disabled &&
        `
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
        `
    }
`;

const PreloaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 144px;
`;

@observer
export class NotificationsList extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        data: PropTypes.object.isRequired
    }

    readAllNotifications = () => {
        return !!this.props.data.notifications.filter(notification => notification.status == "unread").length ?
        this.props.data.actions.readAllNotifications()
        :
        () => {};
    }

    render() {
        const {
            popup,
            actions,
            filters,
            loading,
            notifications,
        } = this.props.data;

        const unreadNotifications = notifications.filter(notification => notification.status == "unread");

        return (
            <Wrapper allNotifications={actions.button_show_all_notifications_disabled}>
                {
                    <FilterBlock>
                        <IconWrapper
                            tabindex="0"
                            onClick={popup.toggleFilters}
                            isFiltersOpen={popup.filtersIsVisible}
                            data-test-control={'notifications-button-toggle-filters'}
                        >
                            <FilterSimpleIcon/>
                        </IconWrapper>
                        <FilterButton
                            onClick={this.readAllNotifications}
                            disabled={!unreadNotifications.length}
                            data-test-control={'notifications-button-read-all-notifications'}
                        >
                            <EOLocale.Text id={'SS904164'} />
                        </FilterButton>
                    </FilterBlock>
                }
                <Scrollbar
                    translateContentSizesToHolder
                    // style={{maxHeight: "calc(100vh - 200px)", height: "calc(" + notifications.length.toString() +"*80px)"}}
                >
                    { notifications.map((notification, i) => (
                        <NotificationItem key={notification.id} notification={notification} actions={actions} itemKey={i}/>
                    )) }
                </Scrollbar>
                { /* враппер обязателен */ }
                { loading &&
                    <PreloaderWrapper>
                        <Preloader padding={'0'}/>
                    </PreloaderWrapper>
                }
                { actions.button_show_all_notifications_disabled ?
                  <AllItemsButton>
                      <ButtonText
                          onClick={actions.loadAllNotifications}
                          data-test-control={'notifications-button-toggle-all-notifications'}
                      >
                          <EOLocale.Text id={'SS904165'} />
                      </ButtonText>
                  </AllItemsButton>
                  :
                  <AllItemsButton>
                      <ButtonText
                          onClick={actions.hideAllNotifications}
                          data-test-control={'notifications-button-toggle-all-notifications'}
                      >
                          Скрыть все уведомления
                      </ButtonText>
                  </AllItemsButton>
                }

                { /* панель с фильтрами по нотификациями */ }
                { popup.filtersIsVisible && <NotificationFilters actions={actions} filters={filters}/> }
            </Wrapper>
        );
    }
}
