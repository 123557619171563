import React from "react";
import { Data } from "./Data";
import {observable, reaction} from "mobx";
import Bell from "GUI_MAIN/icons/Bell";
import styled from "styled-components";
import {observer} from "mobx-react";
import { NotificationsList } from "./NotificationsList";
import { NotificationsCount } from "./NotificationsCount";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {Translator} from "eo-locale";
import {locales} from "Locale";

const NotificationsBlock = styled.div`
    position: relative;
`;

const HeaderIcons = styled.div`
    width: 32px;
    height: 32px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    background-color: ${props => props.isActive && props.theme.headerBellHoverBG};

    &:hover{
        background-color: ${props => props.theme.headerBellHoverBG};
    }
`;

const NotificationsContextualMenu = styled.div`
  position: fixed;
  right: 18px;
  top: 58px;
  width: 480px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.whiteColor};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100000;
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000`;

const BackgroundClose = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
`;

@observer
export class Notifications extends React.PureComponent {

    /**
     * данные о нотификациях
     */
    @observable data = new Data();

    /**
     * иконка с колокольчиком
     */
    @observable bellIcon = {
        ref: React.createRef(),
        right: 0
    };

    constructor(props) {
        super(props);
        reaction(() => HeaderStore.needNotifUpdate, (needNotifUpdate) => {
            if (needNotifUpdate) {
                HeaderStore.setNeedNotifUpdate(false);
                this.data.actions.updateCounter();
                if (this.data.popup.isVisible) {
                    this.data.actions.loadNotifications();
                }
            }
        });
    }

    /**
     * отобразить блок с нотификациями
     */
    showNotifications = () => {
        if( this.data.popup.isVisible ) {
            return console.log("Попап с нотификациями виден");
        }
        HeaderStore.setIsOpenNotificationsPopup(true);
        this.data.actions.clearNotifications();
        this.data.actions.showNotifications();
        this.bellIcon.right = window.innerWidth - this.bellIcon.ref.current.getBoundingClientRect().right - 10;
    };

    /**
     * скрыть блок с нотификациями
     */
    hideNotifications = (e) => {
        e.stopPropagation();
        if ( ! this.data.popup.isVisible ) {
            return console.log("Попап с нотификациями скрыт");
        }
        this.data.actions.hideNotifications();
    };

    /**
     * отрисовка компонента
     */
    render() {
        const translator = new Translator(HeaderStore.locale, locales);
        return (
            <NotificationsBlock>
                <NotificationsCount number={this.data.counter}/>
                <HeaderIcons
                    data-place={"bottom"}
                    data-effect={'solid'}
                    data-tip={translator.getMessageById("SS001029")}
                    ref={this.bellIcon.ref}
                    onClick={this.showNotifications}
                    isActive={this.data.popup.isVisible}
                    data-test-control={"header-button-notifications"}
                >
                    <Bell fill={"white"}/>
                </HeaderIcons>
                {
                    this.data.popup.isVisible &&
                    <BackgroundWrapper>
                        <BackgroundClose onClick={this.hideNotifications}/>
                        <NotificationsContextualMenu>
                            <NotificationsList data={this.data}/>
                        </NotificationsContextualMenu>
                    </BackgroundWrapper>
                }
            </NotificationsBlock>
        )
    }
}
