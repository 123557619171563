import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
    ${props=> props.contentGrow &&
        'display: flex; ' +
        'flex-direction: column;' +
        'height: 100%;' +
        'width: 100%;'};
    background-color: #ffffff;
    margin-bottom: ${props => props.marginBottom ? '24px' : '0'};
    ${props => props.heightAuto && 'height: auto;'};
    ${props => props.minHeight && 'min-height: ' + props.minHeight + 'px' };
`;

const headerBG = (BG, theme) =>{
    switch (BG) {
        case "noCardTitleBg":
            return "#ffffff";
        case "dark":
            return "#3E465F";
        case 'blue':
            return '#F2FAFD';
        default:
            return theme.headerBlockBG;
    }
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props => headerBG(props.headerBG, props.theme)};
  padding: ${ props => props.headerPadding ? props.headerPadding : props.header ? props.buttons ? '8px 24px' : '12px 24px' : '0' };
`;

const titleColor = (BG) => {
    switch (BG) {
        case "noCardTitleBg":
            return "#232735";
        case "dark":
            return "#ffffff";
        case 'blue':
            return "#121232";
        default:
            return "#232735"
    }
};

const CardTitle = styled.div`
  display: flex;
  flex: ${props => props.headerFlex || '0 0 auto'};
  color: ${props => titleColor(props.headerBG)};
  font-family: ${props=>props.theme.OpenSansSemiBold};
  font-size: ${props=>props.theme.bigFontSize};
  line-height: 24px;
  a{
    color: ${props=>props.theme.blackColor};
    &:hover{
      text-decoration: none;
      color: ${props=>props.theme.blackColor};
    }
  }
`;

const Buttons = styled.div`
  flex-grow: ${props => props.notBtnGrow ? '0' : '1'};
`;

const Content = styled.div`
  display: flex;
  flex-direction: ${props => props.elementsDirection || 'row'};
  ${props=> props.contentGrow ? 'flex-grow: 1; height:' + props.contentGrow : ''};
  padding: ${props => props.contentPadding || "0"};
  margin: ${props => props.contentMargin ? props.contentMargin : '0'};
`;

export const Card = (props) => {
    return (
        <CardWrapper contentGrow={props.contentGrow}
                     heightAuto={props.heightAuto}
                     minHeight={props.minHeight}
                     mainBG={props.mainBG}
                     marginBottom={props.marginBottom}>
            <Header header={props.label || props.buttons} buttons={props.buttons} headerBG={props.headerBG} headerPadding={props.headerPadding}>
                {
                    props.label && <CardTitle headerBG={props.headerBG} headerFlex={props.headerFlex}> {props.label} </CardTitle>
                }
                <Buttons notBtnGrow={props.notBtnGrow}>
                    {props.buttons}
                </Buttons>
            </Header>
            <Content elementsDirection={props.elementsDirection} contentGrow={props.contentGrow}
                     contentPadding={props.contentPadding} contentMargin={props.contentMargin}>
                {props.children}
            </Content>
        </CardWrapper>
    )
};
