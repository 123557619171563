import _get from "lodash/get";
import { Patient } from "MODEL_STORE/MIRAMEDIX/Models/Patient";

/**
 * данные контекста перехода
 * https://jira.mmdx.ru/browse/FCSPOK-321
 */
export class ContextData {

    /**
     * пациент
     */
    patient = null;

    /**
     * заболевания
     */
    conditions = [];

    /**
     * инициализация
     */
    constructor(bundle) {
        const resources = _get(bundle, "entry", []).map(entry => _get(entry, "resource"));
        const patient = resources.find(resource => resource.resourceType == "Patient");
        if ( patient ) {
            this.patient = new Patient(patient);
        }
        this.conditions = resources.filter(resource => resource.resourceType == "Condition");
    }

    /**
     * код заболевания из контекста
     */
    get conditionCodes() {
        return this.conditions.reduce((codes, resource) => {
            const coding = _get(resource, ["code", "coding"], []).find(coding => {
                return _get(coding, "system") == "urn:oid:1.2.643.5.1.13.13.11.1005";
            })
            if ( coding ) {
                codes.push(coding.code)
            }
            return codes;
        }, []).join(", ");
    }

}
