import React, {PureComponent, useEffect} from 'react';
import styled from 'styled-components';
import ReactSelect, {createFilter} from "react-select";
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import {labelControlStyles} from "../../../styles/mixins";
import RequiredFieldIcon from "../RequiredFieldIcon";
import {Translator} from "eo-locale";
import {locales} from "Locale";
import {lang_ru} from "Locale/languages/ru";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import _isEmpty from "lodash/isEmpty";
import {tooltipChecker} from "../../Utils/tooltipChecker";
import ReactTooltip from "react-tooltip";

const ReactSelectWrapper = styled.div`
  width: ${props => props.mainWidth || "auto"};
  max-width: ${props => props.maxWidth};
  min-width: ${props => props.minWidth};
  margin: ${props => props.mainMargin || "0"};
  ${props => props.flexGrow && "flex-grow: " + props.flexGrow};

  // .styledReactSelect__single-value--is-disabled{
  //   color: ${props => props.theme.controlTextColorDisabled};
  // }

    .styledReactSelect__control--menu-is-open .styledReactSelect__dropdown-indicator svg {
        transform: rotate(180deg);
        fill: ${ props => props.theme.mainDarkBlueColor };
    }

    input{
    line-height: 20px;
    width: 100% !important;
    }

    .styledReactSelect__input {
        width: 100%;
    }

    .styledReactSelect__control:hover{
        ${ props => props.isDisabled && `border-color: #D3D4D8 !important; background-color: ${props.theme.controlBgColorDisabled} !important;` };
      }
    }

    div[class*="indicatorContainer"]{
      ${ props => props.isDisabled && "color: hsl(0,0%,80%);" };
    }

    .select__input {
      width: 100%;
    }
`;

const InputLabel = styled.div`
  ${props => labelControlStyles(props, false, props.isEmpty)};
  display: block;
`;

const SearchResultNotFound = styled.div`
  word-break: break-word;
`;

class StyledReactSelect extends PureComponent {

    state = {
        open: false,
        focused: false,
        position: this.props.menuPlacement || 'bottom',
        maxMenuHeight: 300
    };

    static propTypes = {
        isError: PropTypes.bool, //Если ошибка
        optionsTooltip: PropTypes.bool, // Нужены ли тултипы options
    };

    static defaultProps = {
        needSearch: false,
        loadingMessage: () => 'Загрузка...',
        classNamePrefix: '',
        maxMenuHeight: 300,
        autoFocus: false,
        menuAutoSize: false,
        noOptionsMessageSearchText: false,
        onFocus: () => false,
        onBlur: () => false,
        optionsTooltip: true
    };

    noOptionsMessageText = null;

    noOptionsMessage = () => {
        const translator = new Translator(HeaderStore.locale, locales);
        return translator.getMessageById("SS011005");
    }

    getNoOptionsMessageText = () => {
        if (HeaderStore.locale == 'ru')
            return  <SearchResultNotFound> По запросу <strong>"{this.noOptionsMessageText}"</strong> ничего не найдено</SearchResultNotFound>; // Номер SS9038450, но не используется так как нужно выделить value жирным
        return <SearchResultNotFound> Nothing was found with the query <strong>"{this.noOptionsMessageText}"</strong></SearchResultNotFound>;
    }

    calculateListPlacement = () => {
        let bottomPos = window.innerHeight - this.select.getBoundingClientRect().bottom,
            topPos = this.select.getBoundingClientRect().top;
        this.setState({
            position: bottomPos <= 320 ? 'top' : 'bottom',
            open: !this.state.open,
            maxMenuHeight: bottomPos <= 320 ? Math.min(topPos, 300) : Math.min(bottomPos, 300)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setLineClamp();
    }

    componentDidMount() {
        this.calculateListPlacement();

        this.setLineClamp();
    }

    setLineClamp = () => {
        if(this.state.focused && this.props.optionsTooltip) {
            tooltipChecker("class", "styledReactSelect__option")
        }

        if(this.select) {
            tooltipChecker("select", [this.select])
        }

        if(this.props.optionsTooltipFormat) {
            tooltipChecker("format", "styledReactSelect__option", null, null, null, this.props.optionsTooltipFormat)
        }
    }

    handleEvent = (event) => {
        if (event.type === "mousedown") {
            this.calculateListPlacement();
        }
    }

    handleMenuOpen = () => {
        if(this.props.onMenuOpen) this.props.onMenuOpen();
    }

    /**
     * по умолчанию ReactSelect фильтрует значения, используя value + label
     * если передан параметр filterByLabelOnly - фильтруем только по label
     * иначе используем переданную функцию filterOption (может быть undefined)
     */
    get filterOption() {
        if(this.props.filterByLabelOnly) {
            return createFilter({stringify: option => option.label});
        }
        return this.props.filterOption;
    }

    getNoOptionsMessageFunc = () => {
        return this.props.noOptionsMessageSearchText && this.noOptionsMessageText !== null ? this.getNoOptionsMessageText() :
            (this.props.noOptionsMessage ? this.props.noOptionsMessage() : this.noOptionsMessage())
    }

    /**
     * при фокусе на элементе
     */
    onFocus = (event) => {
        this.setState({focused: true});
        return this.props.onFocus(event);
    }

    /**
     * при потере фокуса на элементе
     */
    onBlur = (event) => {
        this.setState({focused: false});
        return this.props.onBlur(event);
    }

    /**
     * флаг ошибки (при валидации)
     */
    get isError() {
        if ( this.props.resetErrorOnFocus && this.state.focused ) {
            return false;
        }
        return this.props.isError;
    }

    /**
     * отрисовка компонента
     * backspaceRemovesValue - Remove the currently focused option
     * when the user presses backspace when Select isClearable or isMulti
     */
    render() {

        const {
            mainMargin,
            mainWidth,
            label,
            value,
            onChange,
            options,
            needSearch,
            defaultValue,
            isDisabled,
            isSearchable,
            onInputChange,
            placeholder,
            noOptionsMessage,
            onKeyDown,
            loadingMessage,
            maxMenuHeight,
            isMulti,
            isMenuOpen,
            onMenuOpen,
            otherProps = {},
            defaultMenuIsOpen,
            onMenuClose,
            classNamePrefix,
            menuPlacement,
            isClearable,
            nonPortal,
            requiredField,
            autoFocus,
            components,
            closeMenuOnSelect,
            blurInputOnSelect,
            maxWidth,
            minWidth,
            inputValue,
            formatOptionLabel,
            autosize,
            menuAutoSize,
            className,
            async,
            getOptionLabel,
            getOptionValue,
            menuIsOpen,
            loadOptions,
            backspaceRemovesValue,
            noOptionsMessageSearchText,
            flexGrow,
            noOptionsTooltip,
            isLoading,
            dataTestControl,
            dataTestElemId,
        } = this.props;

        const {
            position,
        } = this.state;

        const {
            onBlur,
            onFocus,
            isError
        } = this;

        const errorPrefix = isError ? 'styledReactSelect__isError ' : ' ';

        const styles = {
            option: (styles, {data}) => {
                return {
                    ...styles,
                    //...data.styles,
                    color: data.color || '#232735',
                    cursor: 'pointer',
                    fontSize: '14px',
                    maxHeight: '70px',
                    lineHeight: '21px',
                    fontFamily: "'opensans', Arial, sans-serif",
                    padding: '6px 12px',
                    overflow: 'hidden',
                };
            },
            menuPortal: (base) => {
                return {
                    ...base,
                    zIndex: 99999,
                    position: 'fixed',
                };
            },
            singleValue: (styles, state) => {
                return {
                    ...styles,
                    color: state.data.color || '#232735',
                    lineHeight: '20px',
                    display: state.selectProps.isSearchable && state.selectProps.menuIsOpen && "none"
                };
            },
            control: (provided, state) => {
                return {
                    ...provided,
                    borderColor: state.isFocused ? '#A1A4B1' : '#A1A4B1',
                    paddingLeft: '8px',
                    backgroundColor: state.isDisabled ? '#F0F1F3' : '#ffffff',
                    borderTopLeftRadius: state.menuIsOpen ? position === 'bottom' ? '4px' : '0' : '4px',
                    borderTopRightRadius: state.menuIsOpen ? position === 'bottom' ? '4px' : '0' : '4px',
                    borderBottomLeftRadius: state.menuIsOpen ? position === 'bottom' ? '0' : '4px' : '4px',
                    borderBottomRightRadius: state.menuIsOpen ? position === 'bottom' ? '0' : '4px' : '4px',
                }
            },
            input: (provided, state) => {
                return {
                    ...provided,
                    width: "100%",
                    pointerEvents: "none"
                }
            },
            menu: (provided, state) => {
                const isAuto = menuAutoSize ? 'auto' : "100%",
                      isBottom = state.placement === 'bottom';
                return {
                    ...provided,
                    maxHeight: this.state.maxMenuHeight,
                    width: isAuto,
                    whiteSpace: menuAutoSize && 'nowrap',
                    marginTop: isBottom ? '-1px' : '0',
                    marginBottom: isBottom ? '8px' : '-1px',
                    borderTopLeftRadius: isBottom ? '0' : '4px',
                    borderTopRightRadius: isBottom ? '0' : '4px',
                    borderBottomLeftRadius: isBottom ? '4px' : '0',
                    borderBottomRightRadius: isBottom ? '4px' : '0',
                    borderTop: isBottom ? '0' : '1px',
                    borderBottom: isBottom ? '1px' : '0',
                    border: '1px solid #1B75BB',
                    overflow: 'hidden',
                }
            },
            multiValue: () => {
                return {
                    backgroundColor: "#E7F5FD",
                    borderRadius: "4px",
                    padding: "2px 12px",
                    display: "flex",
                    margin: "2px",
                    color: "#232735",
                    fontSize: "14px",
                    lineHeight: "20px",
                    maxWidth: "100%",
                }
            },
            multiValueRemove: () => {
                return {
                    cursor: "pointer",
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center"
                }
            }
        };

        const multiPrefix = isMulti ? 'select' : 'styledReactSelect';

        const translator = new Translator(HeaderStore.locale, locales);
        const placeholderText =  (placeholder && lang_ru.messages[placeholder]) ?
            translator.getMessageById(placeholder) : placeholder;

        const mainOptions = {
            value: value,
            onChange: onChange,
            options: options,
            isSearchable: needSearch || isSearchable,
            defaultValue: defaultValue,
            isDisabled: isDisabled,
            filterOption: this.filterOption,
            onInputChange: (value, payload) => {
                tooltipChecker("class", "styledReactSelect__single-value");
                onInputChange && onInputChange(value, payload);
                this.noOptionsMessageText = value;
            },
            placeholder: requiredField && !isClearable && value ? value : (placeholderText ? placeholderText : ""),

            noOptionsMessage: this.getNoOptionsMessageFunc,
            className: isClearable ? 'clearable-select smallHeightSelect ' + className : 'smallHeightSelect ' + className,
            classNamePrefix: classNamePrefix + ' ' + errorPrefix + ' ' + multiPrefix,
            menuShouldBlockScroll: true,
            menuPortalTarget: nonPortal ? null : document.body,
            styles: styles,
            onFocus: onFocus,
            onBlur: onBlur,
            onKeyDown: onKeyDown,
            inputValue: inputValue,
            loadingMessage: loadingMessage,
            maxMenuHeight: maxMenuHeight || this.state.maxMenuHeight,
            onMenuOpen: this.handleMenuOpen,
            defaultMenuIsOpen: defaultMenuIsOpen,
            onMenuClose: () => {
                ReactTooltip.hide();
                // tooltipChecker("class", "styledReactSelect__single-value");
                onMenuClose && onMenuClose();
                this.noOptionsMessageText = null;
            },
            isMulti: isMulti,
            menuPlacement: this.state.position,
            isClearable: isClearable,
            autoFocus: autoFocus,
            components: components,
            closeMenuOnSelect: closeMenuOnSelect,
            blurInputOnSelect: blurInputOnSelect,
            autosize: autosize,
            formatOptionLabel: formatOptionLabel,
            getOptionLabel: getOptionLabel,
            getOptionValue: getOptionValue,
            menuIsOpen: menuIsOpen,
            loadOptions: loadOptions,
            backspaceRemovesValue: backspaceRemovesValue,
            isLoading
        }

        return (
            <ReactSelectWrapper ref={e => this.select = e}
                                mainMargin={mainMargin} mainWidth={mainWidth}
                                maxWidth={maxWidth} minWidth={minWidth}
                                flexGrow={flexGrow}
                                onMouseDown={(e) => {e.stopPropagation(); this.handleEvent(e)}}
                                isDisabled={isDisabled}
                                data-test-control={dataTestControl}
                                data-test-element-id={dataTestElemId}
            >
                {
                    label ?
                        <InputLabel label={label} isError={isError} isEmpty={_isEmpty(value)}>
                            {label}
                            {
                                requiredField && !isDisabled &&
                                <RequiredFieldIcon/>
                            }
                        </InputLabel> : null
                }
                {
                    !async ?
                        <ReactSelect
                            {...mainOptions}
                            {...otherProps}
                        />
                        :
                        <AsyncSelect
                            {...mainOptions}
                            {...otherProps}
                        />
                }
            </ReactSelectWrapper>
        )
    }
}

export default StyledReactSelect;
