import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {iconButtonActiveStyles, iconButtonHoverStyles, iconButtonStyles} from "../../../styles/mixins";
import ReactTooltip from "react-tooltip";

const setIconColor = (disabled, iconColor, theme) => {
    if(disabled && iconColor && iconColor === "darkblue_sign") return theme.mainDarkBlueColor;
    if(disabled && iconColor && iconColor === "darkblue") return theme.mainDarkBlueColor;

    if (disabled && iconColor) {
        switch (iconColor) {
            case 'darkblue':
            case 'darkblue_sign':
                return theme.mainDarkBlueColor;
            case 'green_sign':
                return theme.mainGreenColor;
            case 'red_sign':
                return theme.mainRedColor;
        }
    }

    if(disabled) return theme.iconButtonDisabled;

    if(iconColor){
        switch (iconColor) {
            case 'green':
                return theme.mainGreenColor;
            case 'red':
                return theme.mainRedColor;
            case 'darkblue':
                return theme.mainDarkBlueColor;
            case 'darkblue_sign':
                return theme.mainDarkBlueColor;
            case 'default':
                return theme.iconButtonDEfault
        }
    }

    return theme.iconButtonDEfault;
};

const Icon = styled.div`
  display: flex;
  align-items: center;
  ${props => iconButtonStyles(props, {width: props.widthButton, height: props.heightButton})};
  background-color: ${props => !props.isActive ? "transparent" : props.theme.iconButtonActiveBG + " !important"};

  svg {
    ${props => props.widthIcon && `width: ${props.widthIcon}`};
    ${props => props.heightIcon && `height: ${props.heightIcon}`};
  }
`;

const Label = styled.span`
  display: block;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.mediumFontSize};
  color: ${props => props.labelColor || props.theme.darkMainTextColor};
  margin-left: 5px;
`;

const IconButtonWrapper = styled.div`
  margin: ${props => props.margin || '0'};
  pointer-events: ${props => props.disabled && !props.needTooltip && 'none'};
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  display: flex;
  align-items: center;

  svg{

    path[fill], rect {
        transition: ${props => props.theme.mainTransition};
        fill: ${props => setIconColor(props.disabled, props.iconColor, props.theme)};

      }
      path[stroke], circle{
          transition: ${props => props.theme.mainTransition};
          stroke: ${props => setIconColor(props.disabled, props.iconColor, props.theme)};
      }

  }

  &:hover{
    ${Icon} {
      ${props => !props.disabled && !props.needTooltip && iconButtonHoverStyles(props)};
    }
    ${Label} {
      color: ${props => !props.disabled && !props.needTooltip && props.theme.mainDarkBlueColor};
    }
  }

  &:active{
    ${Icon} {
      ${props => !props.disabled && !props.needTooltip && iconButtonActiveStyles(props)};
    }
    ${Label} {
      color: ${props => !props.disabled && !props.needTooltip && props.theme.mainDarkBlueColor};
    }
  }

  &:active, &:hover{
      svg{
        pointer-events: ${props => props.disabled && !props.needTooltip && 'none'};
      }
  }
`;

class IconButton extends PureComponent {

    componentDidMount() {
        ReactTooltip.rebuild()
    }

    componentWillUnmount() {
        ReactTooltip.hide()
    }

    render() {
        const{
            widthButton,
            heightButton,
            widthIcon,
            heightIcon,
            icon,
            onClick,
            margin,
            disabled,
            iconColor,
            isActive,
            dataTip,
            className,
            label,
            needTooltip,
            dataPlacement,
            labelColor,
            dataTestElemId,
            dataTestControl,
        } = this.props;

        return (
            <IconButtonWrapper
                onClick={!disabled ? onClick : ()=>{}}
                margin={margin}
                disabled={disabled}
                iconColor={iconColor}
                isActive={isActive}
                data-tip={dataTip ? dataTip : null}
                data-place={dataPlacement ? dataPlacement : "bottom"}
                className={className}
                needTooltip={needTooltip}
                data-effect={'solid'}
                data-test-control={dataTestControl}
                data-test-element-id={dataTestElemId}
            >
                <Icon
                    widthButton={widthButton}
                    heightButton={heightButton}
                    widthIcon={widthIcon}
                    heightIcon={heightIcon}
                    disabled={disabled}
                    isActive={isActive}
                >
                    {icon}
                </Icon>
                {
                    label && <Label labelColor={labelColor}>{label}</Label>
                }
            </IconButtonWrapper>
        );
    }
}

IconButton.propTypes = {};

export default IconButton;
