import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled, {withTheme} from "styled-components";
import {setLineHeight} from "GUI_MAIN/Utils/setLineHeight";
import Scrollbar from "react-scrollbars-custom";
import {errorBoundary} from "../../ErrorBoundary/ErrorBoundary";
import ReactTooltip from "react-tooltip";
import {tooltipChecker} from "../../Utils/tooltipChecker";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${props => props.mainBorder || "none"};
  border-radius: ${props => props.needMainBorderRadius || "0"};
  height: ${props => props.mainHeight || "100%"};
  margin: ${props => props.mainMargin || "0"};
`;

const TableRow = styled.div`
  display: flex;
  border-bottom: ${props => props.noBottomBorder ? 'none' : `1px solid ${props.theme.rowBorderColor}`};
  cursor: ${props => props.noClickOnRow ? 'default' : 'pointer'};
  padding: ${props => props.rowPadding || "0"};
  background-color: ${props => props.rowBG || 'transparent'};

  &:last-child{
    ${props => props.noLastBorder && 'border-bottom: none;'};
  }

  &:last-child{
    border-bottom: ${props => props.mainBorder && 'none'};
  }

  &:hover, &:active{
    background-color: ${props => {if(!props.noClickOnRow) return props.theme.hoverRowBG}};

    .mkb-code-chip {
        background: #1B75BB1A;
    }
  };

`;

const setHeaderBG = (bg, theme) => {
    switch (bg) {
        case 'lightGray':
            return theme.lightTableHeaderBG;
        case 'white':
            return theme.whiteHeaderBG;
        case 'transparent':
            return 'transparent';
        default:
            return theme.darkTableHeaderBG;
    }
};

const setHeaderFontColor = (bg, theme) => {
    switch (bg) {
        case 'lightGray':
        case 'white':
        case 'transparent':
            return theme.darkTableHeaderBG;
        default:
            return theme.whiteColor;
    }
};

const TableHeader = styled.div`
  display: flex;
  background-color: ${props => setHeaderBG(props.headerBG, props.theme)};
  color: ${props => setHeaderFontColor(props.headerBG, props.theme)};
  border-bottom: ${props => props.headerBorder || props.headerBorderBottom ? `1px solid ${props.theme.rowBorderColor}` : 'none'};
  border-top: ${props => props.headerBorder ? '1px solid #D3D4D8' : 'none'};
  padding: ${props => props.headerPadding || "10px 0 10px 13px"};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  height: ${props => props.heightBodyContent || '100%'};
  position: relative;
  background-color: ${props => props.bodyBG || props.theme.whiteColor};
`;

const TableCellHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: ${props => props.justifyContent || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
  flex: ${props => props.template};
  font-size: ${props => props.theme.normalFontSize};
  line-height: ${props => setLineHeight(props.theme.normalFontSize)};
  font-family: ${props => props.theme.OpenSansBold};
  padding: ${props => props.cellHeaderPadding || "0"};
  //border: 1px solid red;
`;

const TableCell = styled.div`
   flex: ${props => props.template};
   display: flex;
   justify-content: ${props => props.justifyContent || 'flex-start'};
   text-align: ${props => props.justifyContent || 'auto'};
   align-items: ${props => props.alignItems};
   padding: ${props => props.cellPadding};
   font-family: ${props => props.theme.OpenSansRegular};
   font-size: ${props => props.theme[props.fontSize] || props.theme.mediumFontSize};
   color: ${props => props.theme[props.textColor] || props.theme.darkMainTextColor};
   word-break: break-word;
   border: ${props => props.borderCell ? `1px solid ${props.theme.rowBorderColor}` : `none`};
   min-width: 0;
   //border: 1px solid red;
`;

@errorBoundary(null, null, null, null, "Table")
@withTheme
export default class Table extends React.Component {

    componentDidMount() {
        if(this.props.needTooltipChecker) tooltipChecker("class", "check-tooltip")
    }

    static propTypes = {
        tableTemplate: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
        onRowClick: PropTypes.func,
        noClickOnRow: PropTypes.bool,
        needHeader: PropTypes.bool,
        headerBG: PropTypes.string,
        noBottomBorder: PropTypes.bool,
        headerBorder: PropTypes.bool,
        cellPadding: PropTypes.string,
        mainBorder: PropTypes.string,
        heightTableContent: PropTypes.string,
        minHeightTableContent: PropTypes.string,
        borderCell: PropTypes.bool,
        needTooltipChecker: PropTypes.bool,
        headerBorderBottom: PropTypes.bool,
        showHideInputsBorders: PropTypes.bool,
        needMainBorderRadius: PropTypes.string,
        rowPadding: PropTypes.string,
        headerPadding: PropTypes.string,
        cellHeaderPadding: PropTypes.string,
        rowStyle: PropTypes.object,
        noLastBorder: PropTypes.bool,
    };

    static defaultProps = {
        noClickOnRow: false,
        needHeader: true,
        onRowClick: () => {},
        noBottomBorder: false,
        headerBorder: false,
        cellPadding: '14px 16px',
        alignItems: 'flex-start',
        borderCell: false,
        heightTableContent: '120px',
        noLastBorder: false,
    };

    renderRow = (item, i) => {
        const {
            tableTemplate,
            noBottomBorder,
            noClickOnRow,
            cellPadding,
            alignItems,
            borderCell,
            rowPadding,
            onRowClick,
            mainBorder,
            noLastBorder,
        } = this.props;

        return(
            <TableRow
                key={'table-row-' + i} // хром-дебаггер криво обрабатывает обратный апостроф, поэтому заменил на обычную строку
                noBottomBorder={noBottomBorder}
                noClickOnRow={noClickOnRow || !item.rowTemplate.click}
                className="table-tablerow"
                rowPadding={rowPadding}
                style={item.rowStyle}
                onClick={!noClickOnRow ? item.rowTemplate.click : null}
                rowBG={item.rowBG}
                mainBorder={mainBorder}
                noLastBorder={noLastBorder}
                data-test-control={item.rowTemplate.dataTestControlRow}
                data-test-element-id={item.rowTemplate.dataTestElemIdRow}
            >
                {
                    tableTemplate.map((el, j) => {
                        return (
                            <TableCell
                                key={'table-row-' + i + '-cell-' + j}
                                onClick={() => onRowClick ? onRowClick(item.serviceRequestID) : null}
                                template={el.flex}
                                justifyContent={el.justify}
                                cellPadding={cellPadding}
                                alignItems={alignItems}
                                textColor={el.textColor}
                                fontSize={el.fontSize}
                                borderCell={borderCell}
                                className="table-tablecell"
                            >
                                {item.rowTemplate[el.field]}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        )
    };

    render() {
        const {
            tableTemplate, //структура таблицы, задается в js-файле
            data, //данные таблицы
            headerBG, //фон заголовка хэдера
            headerBorder, //рамка заголовка таблицы
            needHeader, //если нужен хэдер. по умолчанию -- true, не нужен -- false
            theme,
            onRowClick,
            noClickOnRow,
            heightTableContent,
            minHeightTableContent,
            mainBorder,
            headerBorderBottom,
            needMainBorderRadius,
            headerPadding,
            cellHeaderPadding,
            notNeedScrollbar,
            heightBodyContent,
            mainMargin,
            mainHeight,
            autoHeight
        } = this.props;

        return (
            <TableWrapper
                mainMargin={mainMargin}
                mainBorder={mainBorder}
                needMainBorderRadius={needMainBorderRadius}
                mainHeight={mainHeight}
            >
                {needHeader &&
                    <TableHeader
                        headerBG={headerBG}
                        headerBorder={headerBorder}
                        headerBorderBottom={data.length === 0 ? false : headerBorderBottom}
                        headerPadding={headerPadding}
                    >
                        {
                            tableTemplate.map((col, index) =>
                                <TableCellHeader
                                    key={index}
                                    template={col.flex}
                                    justifyContent={col.justify}
                                    cellHeaderPadding={cellHeaderPadding}
                                >
                                    {col.title}
                                </TableCellHeader>
                            )
                        }
                    </TableHeader>
                }
                    <TableBody
                        needHeader={needHeader}
                        heightBodyContent={heightBodyContent}
                    >
                        {
                            !notNeedScrollbar ?
                                <Scrollbar
                                    translateContentSizesToHolder={autoHeight}
                                    style={{
                                        maxHeight: heightTableContent,
                                        minHeight: minHeightTableContent || "100px",
                                    }}
                                    noScrollX={true}
                                >
                                    <div>
                                    {
                                        data.map((el, i) => this.renderRow(el, i))
                                    }
                                    </div>
                                </Scrollbar>
                                : data.map((el, i) => this.renderRow(el, i))
                        }
                    </TableBody>
            </TableWrapper>
        );
    }
}
