import React, {PureComponent} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {controlStyles, controlStylesActive, controlStylesHover, labelControlStyles} from "../../../styles/mixins";
import RequiredFieldIcon from "../RequiredFieldIcon";
import {Translator} from "eo-locale";
import {locales} from "Locale";
import {lang_ru} from "Locale/languages/ru";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {computed} from "mobx";
import {observer} from "mobx-react";
import SelectClear from "../../icons/SelectClear";

const TextAreaWrapper = styled.div`
  width: ${props => props.mainMaxWidth || '100%'};
  padding: ${props => props.padding || '0'};
`;

const TextAreaInput = styled.textarea.attrs({type: 'textarea'})`
  width: 100%;
  line-height: 16px;
  padding: ${props => !props.isClearable ? "6px 12px" : "6px 36px 6px 12px"};
  ${props => controlStyles(props, {disabled: props.disabled, isError: props.isError})};
  outline: none;
  //transition: ${props => props.theme.mainTransition};
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: ${props => props.mainMaxHeight ? 'auto' : 'hidden'};
  min-height: ${props => props.mainMinHeight || '32px'} !important;
  ${props => props.mainMaxHeight && `max-height: ${props.mainMaxHeight}px`};
  cursor: auto;

  &:hover{
    ${props => controlStylesHover(props, props.disabled)};
  }

  &:focus, &:active {
    ${props => controlStylesActive(props, props.disabled)};
  }

  ::-moz-placeholder{
      font-size: ${(props) => props.theme.mediumFontSize};
      color: ${props => props.theme.controlPlaceholderColor};
  }

  ::-webkit-scrollbar {width: 6px;}
  ::-webkit-scrollbar-track { background-color: white;}
  ::-webkit-scrollbar-thumb { width: 6px; background-color: rgba(0, 0, 0, 0.2); border-radius: 3px;}

  ::placeholder{
    padding-left: 3px;
  }
`;

const InputLabel = styled.div`
  ${props => labelControlStyles(props, false, props.labelFontSize)};
  display: block;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  // height: ${props => props.mainHeight + "px"};
`;

const IconWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 6px;
  right: 8px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  svg path {
    transition: fill 150ms;
  }

  &:hover {
    svg path {
      fill: hsl(0,0%,60%);
    }
  }
`;

@observer
class TextArea extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            wrapperHeight: 32,
        }

        this.textAreaRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.textAreaRef.current.value !== prevProps.value) {
            this.autosize();
        }
    }

    componentDidMount() {
        this.autosize();
    }

    autosize = () => {
        const el = this;
        const targetTextarea = el.textAreaRef.current;

        if (targetTextarea) {
            if (targetTextarea.value) {
                targetTextarea.style.cssText = 'height: 32px';
                const height = targetTextarea.scrollHeight === 33 ? 32 : targetTextarea.scrollHeight;
                targetTextarea.style.cssText = 'height:' + height + 'px';
                //this.setState({wrapperHeight: targetTextarea.offsetHeight})
            }
            else {
                targetTextarea.style.cssText = 'height: 32px;';
                //this.setState({wrapperHeight: targetTextarea.offsetHeight})
            }
        }
    }


    change = (e) => {
        return this.props.onChange(e);
    }

    onClear = (e) => {
        const { onChange, onBlur} = this.props;

        const el = this;
        const targetTextarea = el.textAreaRef.current;

        targetTextarea.value= "";
        onChange ? onChange(e) : null;
        onBlur ? onBlur(e) : null;
    };

    @computed
    get placeholderText() {
        const { placeholder,} = this.props;
        const translator = new Translator(HeaderStore.locale, locales);
        return placeholder && lang_ru.messages[placeholder] ? translator.getMessageById(placeholder) : placeholder;
    }

    render() {
        const {
            rows,
            onChange,
            onBlur,
            value,
            mainMaxWidth,
            mainMaxHeight,
            label,
            labelFontSize,
            id,
            mainPadding,
            mainMinHeight,
            disabled,
            maxLength,
            type,
            onKeyDown ,
            required,
            padding,
            requiredField,
            onFocus,
            onKeyPress,
            isError,
            isClearable,
            dataTestControl,
            dataTestElemId
        } = this.props;

        return (
            <TextAreaWrapper mainMaxWidth={mainMaxWidth} padding={padding}>
                {
                    label ?
                        <InputLabel label={label} isError={isError} labelFontSize={labelFontSize}>
                            {label}
                            {
                                requiredField && !disabled &&
                                <RequiredFieldIcon/>
                            }
                        </InputLabel> : null
                }
                <ContentWrapper id={id} mainHeight={this.state.wrapperHeight}>
                    <TextAreaInput
                        ref={this.textAreaRef}
                        rows={'1'}
                        isError={isError}
                        type={type}
                        disabled={disabled}
                        id={id ? id + "--textarea" : "textarea"}
                        onChange={(e) => this.change(e)}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                        onBlur={onBlur}
                        placeholder={this.placeholderText}
                        value={value}
                        wrapperId={id}
                        onKeyDown ={onKeyDown }
                        mainPadding={mainPadding}
                        mainMinHeight={mainMinHeight}
                        maxLength={maxLength}
                        mainMaxHeight={mainMaxHeight}
                        isClearable={isClearable}
                        data-test-control={dataTestControl}
                        data-test-element-id={dataTestElemId}
                    />
                    {
                        !disabled && isClearable && value &&
                        <IconWrapper onClick={(e) => this.onClear(e)}>
                            <SelectClear/>
                        </IconWrapper>
                    }
                </ContentWrapper>
            </TextAreaWrapper>
        );
    }
}

TextArea.propTypes = {
    id: PropTypes.string.isRequired,
    mainMaxHeight: PropTypes.number,
    wrapperHeight: PropTypes.number,
    onKeyPress: PropTypes.func,
    isClearable:PropTypes.bool,
};

TextArea.defaultProps = {
    onKeyPress: () => {},
    isClearable: false
};

export default TextArea;
