import { TextFilter } from "./TextFilter";
import { SnilsFilter } from "./SnilsFilter";
import { observable, reaction } from "mobx";
import { Mkb10Filter } from "./Mkb10Filter";
import { GenderFilter } from "./GenderFilter";
import { PaginationFilter } from "./PaginationFilter";
import { OrganizationFilter } from "MODEL_STORE/DataSource/Filters/OrganizationFilter";
import { PractitionerFilter } from "./PractitionerFilter";

/**
 * таймаут перед поиском (в мс)
 */
const TIMEOUT_BEFORE_SEARCH = 500;
export const LOCAL_STORE_FILTER_KEY = "PATIENT_FILTERS";
export const LOCAL_STORE_FIELDS_KEY = "PATIENT_FIELDS";

export class Filters {

    /**
     * данные
     */
    data = null;

    /**
     * таймаут
     */
    timeout = null;

    /**
     * фильтр по полису ОМС
     */
    @observable oms = new TextFilter;

    /**
     * фильтр по номеру мед. карты
     */
    @observable card = new TextFilter;

    /**
     * фильтр по фио пациента
     */
    @observable name = new TextFilter;

    /**
     * фильтр по заболеванию пациента (коду МКБ-10)
     */
    @observable mkb10 = new Mkb10Filter;

    /**
     * фильтр по СНИЛС пациента
     */
    @observable snils = new SnilsFilter;

    /**
     * фильтр по полу пациента
     */
    @observable gender = new GenderFilter;

    /**
     * фильтр по дате рождения пациента
     */
    @observable birthday = new TextFilter;

    /**
     * фильтр по страницам и количеству результатов
     */
    @observable pagination = new PaginationFilter(LOCAL_STORE_FILTER_KEY);

    /**
     * фильтр по организации пациента
     */
    @observable organization = new OrganizationFilter;

    /**
     * фильтр по лечащему врачу пациента
     */
    @observable practitioner = new PractitionerFilter;

    /**
     * этот флажок ставим в true, если нажимали на кнопку поиска после ввода новых данных в параметры поиска
     * визуально - когда состояние меняется с "Укажите значения для поиска пациента и нажмите «Найти»"
     * на результат поиска
     */
    @observable searchHaBeenStarted = false;

    /**
     * при создании объекта регистрируем реакции на изменение фильтров
     */
    constructor(data) {
        this.data = data;

        // сбрасываем флаг начала поиска при изменении фильтров
        reaction(() => this.oms.value, this.resetSearch);
        reaction(() => this.snils.value, this.resetSearch);

        reaction(() => this.pagination.page, () => {
            if ( this.searchHaBeenStarted ) {
                return this.data.search();
            }
        });
        reaction(() => this.pagination.count, () => {
            if ( this.searchHaBeenStarted ) {
                return this.changePageOrSearch({
                    oms: this.oms.value,
                    name: this.name.value,
                    snils: this.snils.value,
                    birthday: this.birthday.value
                });
            }
        });
        reaction(() => this.data.loading, () => {
            if ( !this.data.loading ) {
                // this.loadFieldsFromLocalStore();
            }
        });

        this.loadFromLocalStore();
    }

    loadFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
        if (!filters) {
            // this.loadFieldsFromLocalStore();
            return;
        }

        filters = JSON.parse(filters);

        this.name.value = filters.name;
        this.oms.value = filters.oms;
        this.snils.value = filters.snils;
        this.birthday.value = filters.birthday;

        // Постановка в конец очереди асинхронных задач
        setTimeout(this.changePageOrSearch, 0, {
            name: filters.name,
            oms: filters.oms,
            snils: filters.snils,
            birthday: filters.birthday,
        })
    };

    loadFieldsFromLocalStore = () => {
        this.name.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'name');
        this.oms.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'oms');
        this.snils.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'snils');
        this.birthday.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'birthday');

        let filters = localStorage.getItem(LOCAL_STORE_FIELDS_KEY);
        if (!filters) return null;
        filters = JSON.parse(filters);
        if (filters.name !== undefined)
            this.name.setValue(filters.name);
        if (filters.oms !== undefined)
            this.oms.setValue(filters.oms);
        if (filters.snils !== undefined)
            this.snils.value = filters.snils;
        if (filters.birthday !== undefined)
            this.birthday.setValue(filters.birthday);
    };

    /**
     *
     */
    changePageOrSearch = ({oms, name, snils, birthday}) => {

        localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify({
            oms,
            name,
            snils,
            birthday
        }));

        // выключаем реакцию на смену страницы - иначе поиск сработает ещё раз
        this.searchHaBeenStarted = false;
        this.pagination.setPage(1);
        this.data.search();
        this.searchHaBeenStarted = true;
    }

    onSearchButton = () => {
        this.changePageOrSearch({
            oms: this.oms.value,
            name: this.name.value,
            snils: this.snils.value,
            birthday: this.birthday.value
        });
    }

    deleteLocalStoreData = () => {
        localStorage.removeItem(LOCAL_STORE_FILTER_KEY);
        localStorage.removeItem(LOCAL_STORE_FIELDS_KEY);
        this.oms.reset();
        this.snils.reset();
        this.searchHaBeenStarted = false;
        this.pagination = new PaginationFilter(LOCAL_STORE_FILTER_KEY);
    };

    /**
     * Сбросить поиск
     */
    resetSearch = () => {
        // localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify({
        //     oms: this.oms,
        //     name: this.name,
        //     snils: this.snils,
        //     birthday: this.birthday
        // }));
        this.searchHaBeenStarted = false;
    }

}
